import React from 'react'
import styled from 'styled-components'
import { addMonths, startOfMonth, format, isSameMonth } from 'date-fns'
import { Bar } from 'react-chartjs-2'
import { ChartLabel, ChartImage } from './components'
import { baroptions, colors } from './chartoptions'
import PigImage from '../../shared/graphics/pig.png'

export default ({ to, sales, order_reserves, }) => {

  const from = startOfMonth(new Date())

  const groups = new Array(12).fill(0).map((n,index) => {
    const d = addMonths(from, index)
    return d 
  })
  const labels = groups.map(d => { 
    return format(d, 'MMM')
  })

  const dbPerMonth = groups.map(g => null)
  const salesPerMonth = groups.map(g => 0)

  order_reserves.forEach(sale => {
    if (!sale?.startup) return
    if (!sale?.completed) return
    // eslint-disable-next-line
    if (sale.status != '6') return
    const start = new Date(sale?.startup)
    const periodSum = parseInt(sale.sum / sale.contractperiod)
    new Array(sale.contractperiod).fill(0).forEach((_,index) => {
      const p = addMonths(start, index)
      groups.forEach((g,gi) => {
        if (isSameMonth(p, g)) {
          salesPerMonth[gi] += periodSum
          if (sale.coverage) dbPerMonth[gi] += (sale.coverage * periodSum) / 100
        }
      })
    }) 
  })

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Akkumulert omsetning justert etter hitrate',
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg, 
        data: salesPerMonth,
        order: 2
      },
      {
        type: 'line',
        label: 'Dekningsbidrag',
        data: dbPerMonth,
        order: 1,
        borderWidth: 4,
        pointRadius: 15,
        pointHoverRadius: 15,
        pointHoverBorderWidth: 8,
        pointStyle: 'line', 
        pointBorderColor: 'red',
        backgroundColor: 'transparent',
      },
    ],
  };

  return (
    <Container>
      <ChartLabel>Ordrereserve Salg - Periodisert 12 MND Løpende</ChartLabel>
      <ChartImage src={PigImage} />
      <StyledBar data={chartData} options={baroptions} />
    </Container> 
  )
}

const Container = styled.div`
  width: 80%;
  position: relative;
`

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`

