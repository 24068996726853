import React, { useState, useEffect } from 'react'
import { useStore } from 'react-hookstore'
import Button from '@material-ui/core/Button'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { format, missionRepeatsOnDate } from '../../shared/utils'
import Timeline, { formatTimelineDate } from './Timeline'
import Mission from './Mission'
import './index.css'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((r,i) => {
    r.sort_order = i
  })
  return result;
};

export default (props) =>  {
  const [editing, setEditing] = useState(null)
  const [creating, setCreating] = useState(false)
  const [,setSnackbar] = useStore('snackbar')
  const [missions, setMissions] = useState([]) 
  const [planning, setPlanning] = useState(false)
  const [selectedDate, setSelectedDate] = useState(formatTimelineDate(new Date()))
  const [operators] = useStore('operators')

  useEffect(() => {
    const fetchMissionsForSelectedDate = async () => {
      const missions_res = await fetch(`/api/missions?date=${selectedDate}`)
      if (!missions_res.ok) return setSnackbar({ open: true, message: `Feil ved hending av oppdrag`, variant: 'error', showtime: 3000 })
      const _missions = await missions_res.json()
      setMissions(_missions)
    }
    fetchMissionsForSelectedDate()
  }, [selectedDate, setMissions, setSnackbar])

  //console.log(missions)

  const handleSaveMission = async ({ mission, editing, creating, editState }) => {
    const method = creating ? 'POST' : 'PUT'
    const url = editing ? `/api/missions/${mission.id}` : `/api/missions`
    if (creating && editState.operator) {
      const othersWithSameOperator = missions.filter(m => m.operator === editState.operator).filter(m => !missionRepeatsOnDate(m, selectedDate))
      editState.sort_order = othersWithSameOperator.length
    }
    if (editing && mission?.operator !== editState?.operator) {
      const othersWithSameOperator = missions.filter(m => m.operator === editState.operator).filter(m => !missionRepeatsOnDate(m, selectedDate))
      editState.sort_order = othersWithSameOperator.length
    }
    const body = JSON.stringify(creating ? Object.assign({}, editState) : Object.assign({}, mission, editState))
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
      method,
      body
    })
    if (!res.ok) return setSnackbar({ open: true, message: `Kunne ikke opprette oppdrag`, variant: 'error', showtime: 3000 })
    const _mission = await res.json()
    if (creating) setMissions(missions.concat([_mission]))
    else setMissions(missions.map(m => m.id === _mission.id ? _mission : m))
    setEditing(null)
    setCreating(false)
  }

  const handleEditMission = (mission) => {
    setEditing(mission)
  }

  const handleDeleteMission = async (mission) => {
    const response = window.confirm('Sikker på at du vil slette dette oppdraget?')
    if (!response) return
    const res = await fetch(`/api/missions/${mission.id}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    })
    if (!res.ok) return setSnackbar({ open: true, message: `Kunne ikke slette oppdrag`, variant: 'error', showtime: 3000 })
    setMissions(missions.filter(m => m.id !== mission.id)) 
  }

  const handleCopyMission = async (mission) => {
    const _mission = Object.assign({}, mission)
    delete _mission.id
    if (_mission.ordered) _mission.ordered = format(_mission.ordered,'yyyy-MM-dd')  
    if (_mission.planned) _mission.planned = format(_mission.planned,'yyyy-MM-dd') 
    if (_mission.deadline) _mission.deadline = format(_mission.deadline,'yyyy-MM-dd') 
    await handleSaveMission({ editing: false, creating: true, editState: _mission })
  }

  const handleCancel = () => {
    if (editing) setEditing(null)
    if (creating) setCreating(false)
  }

  const missionCopiedFromIds = []
  const missionOperatorGroups = missions
    .filter(m => m.operator)
    .filter(m => {
      if (!m.planned && !selectedDate) return true // Åpne
      return format(m.planned) === format(selectedDate) // Hører til denne dagen
    })
    .sort((a,b) => {
      if (a.operator === b.operator) {
        return a.sort_order - b.sort_order
      }
      return a.operator > b.operator ? 1 : -1
    })
    .map(m => {
      if (m?.copied_from) missionCopiedFromIds.push(m.copied_from)
      return m
    })
    .reduce((coll,m) => {
      if (!coll[m.operator]) coll[m.operator] = []
      coll[m.operator].push(m)
      return coll
    }, {})

  const missionOperatorGroupsDroppable = Object.keys(missionOperatorGroups)
    .sort((a,b) => {
      // eslint-disable-next-line
      const a_name = operators.find(o => o.id == a)?.name
      // eslint-disable-next-line
      const b_name = operators.find(o => o.id == b)?.name
      return a_name > b_name ? 1 : -1
    })
    .map(operator => {
    const operatorMissions = missionOperatorGroups[operator]
    return (
      <Droppable droppableId={operator.toString()} key={operator}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {operatorMissions.map((m, index) => (
              <Draggable key={m.id} draggableId={m.id.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      userSelect: 'none',
                      margin: '0 0 10px 0',
                      backgroundColor: '#fff',
                      ...provided.draggableProps.style,
                    }}
                  >
                    <Mission 
                      mission={m} 
                      editing={m.id === editing?.id} 
                      onEdit={handleEditMission} 
                      onSave={handleSaveMission} 
                      onCopy={handleCopyMission}
                      onDelete={handleDeleteMission}
                      onCancel={handleCancel} 
                      planning={planning}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  })

  const missionsWithoutOperator = missions
    .filter(m => !m.operator)
    .filter(m => {
      if (!m.planned && !selectedDate) return true // Åpne
      return format(m.planned) === format(selectedDate) // Hører til denne dagen
    })
    .map(m => {
      if (m?.copied_from) missionCopiedFromIds.push(m.copied_from)
      return m
    })
    .sort((a, b) => {
      let a_d = a?.deadline ? new Date(a.deadline) : null;
      let b_d = b?.deadline ? new Date(b.deadline) : null;

      if (!a_d) return 1; // If 'a' has no deadline, it should be last
      if (!b_d) return -1; // If 'b' has no deadline, it should be last
      return a_d > b_d ? 1 : -1;
    })
    .map(m => {
      return (
        <Mission 
          key={m.id}
          mission={m} 
          editing={m.id === editing?.id} 
          onEdit={handleEditMission} 
          onSave={handleSaveMission} 
          onCopy={handleCopyMission}
          onDelete={handleDeleteMission}
          onCancel={handleCancel} 
          planning={planning}
        />
      )
    })

  const missionsRepeated = missions
    .filter(m => m.repeat)
    .filter(m => missionRepeatsOnDate(m, selectedDate))
    .filter(m => missionCopiedFromIds.indexOf(m.id) < 0)
    .map(m => {
      return (
        <Mission 
          key={m.id}
          mission={m} 
          editing={m.id === editing?.id} 
          onEdit={handleEditMission} 
          onSave={handleSaveMission} 
          onCopy={handleCopyMission}
          onDelete={handleDeleteMission}
          onCancel={handleCancel} 
          planning={planning}
          repeatClone={true}
          selectedDate={selectedDate}
        />
      )
    })


  if (creating) 
    missionOperatorGroupsDroppable.unshift(
      <Mission key="creating" creating={true} onSave={handleSaveMission} onCancel={handleCancel} selectedDate={selectedDate} />
    )

  const onDragEnd = async (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const updatedMissions = reorder(
      // eslint-disable-next-line
      missions.filter(mission => mission.operator == source.droppableId).filter(m => !missionRepeatsOnDate(m, selectedDate)),
      source.index,
      destination.index
    );

    const updatedMissionIds = updatedMissions.map(m => m.id)

    setMissions(prevMissions =>
      prevMissions
        // eslint-disable-next-line
        .filter(m => updatedMissionIds.indexOf(m.id) < 0)
        .concat(updatedMissions)
    );

    for (const mission of updatedMissions) {
      const res = await fetch(`/api/missions/${mission.id}`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify({ sort_order: mission.sort_order })
      })
      if (!res.ok) return setSnackbar({ open: true, message: `Feil ved sortering av oppdrag`, variant: 'error', showtime: 3000 })
    }
  };

  return (
    <div className="MainBody Missions">
      <div className="top">
        <h1>Oppdrag</h1>
        <div className="buttons">
          <Button variant="contained" color="primary" onClick={() => setCreating(!creating)}>Nytt oppdrag</Button>
          <Button variant="contained" color="primary" onClick={() => setPlanning(!planning)}>Planlegg</Button>
          <Button variant="contained" color="primary" onClick={() => setSelectedDate(null)}>Bestillinger</Button>
        </div> 
      </div>
      <div className="timeline">
        <Timeline today={new Date()} onDateChange={(date) => setSelectedDate(date)} />
      </div>
      <div className="selectedView">
        {format(selectedDate) || 'Bestillinger'}
      </div>
      <div className="missionlist">
        {missionsRepeated}
        <DragDropContext onDragEnd={onDragEnd}>
          {missionOperatorGroupsDroppable}
        </DragDropContext>
        {missionsWithoutOperator}
      </div>
    </div>
  )
}
