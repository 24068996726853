import React, { useState } from 'react'
import { useStore } from 'react-hookstore'
import { nav } from 'tiny-react-router'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { init } from '../../shared/state'
import './index.css'

export default (props) => {
  const [, setSnackbar] = useStore('snackbar')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showForgot, setShowForgot] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (showForgot)
      await handleNewPassword()
    else
      await handleLogin()
  }

  const handleLogin = async () => {
    const login_res = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: username, password: password })
    })
    if (login_res.status !== 200) return setSnackbar({ open: true, message: `Ukjent eller feil brukernavn eller passord`, variant: 'error', showtime: 3000 })
    await init()
    setSnackbar({ open: true, message: "Innlogging velykket", variant: 'success', showtime: 3000 })
    nav('/')
  }

  const handleNewPassword = async () => {
    const newpass_res = await fetch(`/api/newpassword`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username })
    })
    if (!newpass_res.ok) {
      const errmsg = await newpass_res.text()
      return setSnackbar({ open: true, message: errmsg, variant: 'error', showtime: 3000 })
    }
    setSnackbar({ open: true, message: "Epost sendt", variant: 'success', showtime: 3000 })
  }

  const forgotPasswordButtonText = showForgot ? 'Tilbake' : 'Glemt passord'
  const loginButtonText = showForgot ? 'Send epost' : 'Logg inn'

  return (
    <div className="MainBody Login">
      <div className="box">
        <h2>Logg inn</h2>
        <form onSubmit={handleSubmit}>
          <TextField 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            id="username"
            variant="outlined" 
            className="formInput" 
            type="text"
            label="Brukernavn"
            InputLabelProps={{
              shrink: true,
            }} 
            required={true} />
          { !showForgot &&
            <TextField 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              variant="outlined" 
              className="formInput" 
              type="password"
              label="Passord"
              InputLabelProps={{
                shrink: true,
              }} 
              required={true} />
          }
          <div className="buttons">
            <div className="forgotPassword" onClick={() => setShowForgot(!showForgot)}>{forgotPasswordButtonText}</div>
            <Button variant="contained" color="primary" type="submit">{loginButtonText}</Button>
          </div>
        </form>
      </div>
    </div>
  )
}
