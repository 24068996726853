export const colors = {
  bar: 'rgba(75,192,192,0.4)',
  teal: 'rgba(75,192,192,1)',
  peach: '#F0886F',
  light: '#43AAA7',
  lighter: '#5FC1BF',
  hoverbg: 'rgba(75,192,192,0.7)',
  hoverborder: 'rgba(75,192,192,1)',
  background: '#1A3755'
}

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
}

export function createDiagonalPattern({ color, fill, spacing=10 }) {
  let canvas = document.createElement('canvas');
  canvas.width = spacing*2;
  canvas.height = spacing*2;
  let ctx = canvas.getContext('2d');

  // Fill the background white
  ctx.fillStyle = fill;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Draw the diagonal lines
  ctx.strokeStyle = color;
  ctx.beginPath();
  // Adjust these points to change the angle of the stripes
  ctx.moveTo(0, canvas.height);
  ctx.lineTo(canvas.width, 0);
  ctx.moveTo(0, spacing);
  ctx.lineTo(spacing, 0);
  ctx.moveTo(spacing, canvas.height);
  ctx.lineTo(canvas.width, spacing);
  ctx.stroke();

  return ctx.createPattern(canvas, 'repeat');
}

export const lineoptions = Object.assign([], defaultOptions, {
  layout: {
    padding: {
      top: 30
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
    datalabels: {
      display: true,
      align: 'end',
      anchor: 'end',
      color: 'white',
      font: {
        size: 12,
      },
      formatter: (value, context) => {
        if (value === 0) return null
        return value
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0
      },
    },
    y: {
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
});

export const baroptions = Object.assign({}, defaultOptions, {
  layout: {
    padding: {
      top: 50
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
    datalabels: {
      display: false,
      align: 'end',
      anchor: 'end',
      color: 'white',
      font: {
        size: 12,
      },
      formatter: (value, context) => {
        if (value === 0) return null
        return value
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0
      },
    },
    y: {
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
});
  
export const rotatedbaroptions = Object.assign({}, defaultOptions, {
  indexAxis: 'y',
  layout: {
    padding: {
      top: 50,
      right: 30
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
    datalabels: {
      display: true,
      align: 'end',
      anchor: 'end',
      color: 'white',
      font: {
        size: 12,
      },
      formatter: (value, context) => {
        if (value === 0) return null
        return value
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0
      },
    },
    y: {
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
})

export const rotateddualbaroptions = Object.assign({}, defaultOptions, {
  indexAxis: 'y',
  layout: {
    padding: {
      top: 50,
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
    datalabels: {
      display: true,
      align: 'center',
      anchor: 'start',
      color: colors.background,
      font: {
        size: 12,
      },
      formatter: (value, context) => {
        if (value < 0) return null
        return value
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        drawBorder: false,
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        drawBorder: false,
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        display: false,
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
})

export const rotateddoublebaroptions = Object.assign({}, defaultOptions, {
  indexAxis: 'y',
  layout: {
    padding: {
      top: 50,
      right: 30
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
  },
  scales: {
    x: {
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0
      },
    },
    y: {
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
})

export const rotateddoublebaroptionstype = Object.assign({}, defaultOptions, {
  indexAxis: 'y',
  layout: {
    padding: {
      top: 50,
      right: 50
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
  },
  scales: {
    x: {
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0
      },
    },
    y: {
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
})

export const baroptionsstacked = Object.assign({}, defaultOptions, {
  layout: {
    padding: {
      top: 60
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
});

export const top3salesoptions = Object.assign({}, defaultOptions, {
  indexAxis: 'y',
  layout: {
    padding: {
      top: 50,
      left: 50,
      right: 40
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
  },
  scales: {
    x: {
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0
      },
    },
    y: {
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
})

export const tilbudsvolumoptions = Object.assign({}, defaultOptions, {
  layout: {
    padding: {
      top: 60
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable the on-hover tooltip
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false // this will remove the x-axis grid lines
      },
      ticks: {
        autoSkip: false,
        color: "white", // Change the y-axis tick color to white
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false // this will remove the y-axis grid lines
      },
      ticks: {
        color: "white", // Change the y-axis tick color to white
      },
    }
  }
});
