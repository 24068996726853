import React from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { useStore } from 'react-hookstore'
import { ChartLabel } from './components'
import { rotatedbaroptions, colors } from './chartoptions'

export default ({ to, from, sales }) => {
  const [segments] = useStore('segment')

  const segment_counts = {}
  const segment_ids = sales
    // eslint-disable-next-line
    .filter(s => s.status == '6')
    .reduce((labels, s) => {
      if (labels.indexOf(s.segment) < 0) {
        labels.push(s.segment)
        segment_counts[s.segment] = 0
      }
      segment_counts[s.segment] += 1
      return labels
    }, [])

  // eslint-disable-next-line
  const found_segments = segment_ids.map(sid => segments.find(s => s.id == sid))
    .sort((a,b) => {
      if (segment_counts[a.id] > segment_counts[b.id]) return -1
      if (segment_counts[a.id] < segment_counts[b.id]) return 1
      return 0
    })

  const dataset = found_segments.map(fs => segment_counts[fs.id]) 
  
  const data = {
    labels: found_segments.map(s => s.name), 
    datasets: [
      {
        data: dataset, 
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg,
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartLabel>Salg etter segment</ChartLabel>
      <StyledBar data={data} options={rotatedbaroptions} />
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 20%;
  position: relative;
`;

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`
