import React from 'react';
import styled from 'styled-components';
import { getYear, getWeek, getQuarter } from 'date-fns'
import { ChartLabelInline, ChartSum } from './components'
import { colors } from './chartoptions'
import { numberFormatterKrNoDec } from './../../shared/utils'
import CrosshairImage from '../../shared/graphics/crosshair.png'

export default ({ to, from, sales, goal, average_sum }) => {

  const currentYear = new Date().getFullYear()
  const quarterSales = {
    1: 0, 2: 0, 3: 0, 4: 0
  }
  let salesSumYTD = 0
  sales.forEach(sale => {
    // eslint-disable-next-line
    if (sale.status != '6') return
    if (!sale?.completed) return
    const year = getYear(new Date(sale.completed))
    if (year === currentYear) {
      const quarter = getQuarter(new Date(sale?.completed)); // -1 to make it 0-indexed
      quarterSales[quarter] += sale.sum
      salesSumYTD += sale.sum
    }
  })

  const currentWeek = getWeek(new Date())
  const weeklyTarget = goal/52
  const weeklyAverage = salesSumYTD/currentWeek 
  const distanceFromGoal = salesSumYTD - goal

  if (isNaN(average_sum)) average_sum = 0

  return (
    <ChartContainer>
      <ChartLabelInline>Omsetning per salg, gjennomsnitt</ChartLabelInline>
      <ChartSum>{numberFormatterKrNoDec(average_sum)}</ChartSum>
      <ChartLabelInline>Salg pr. uke, gjennomsnitt</ChartLabelInline>
      <ChartSum>{numberFormatterKrNoDec(weeklyAverage)}</ChartSum>
      <WeeklyTargetContainer>
        <img src={CrosshairImage} alt="crosshair" />
        <WeeklyTargetValue>{numberFormatterKrNoDec(weeklyTarget)}</WeeklyTargetValue>
      </WeeklyTargetContainer>
      <QuarterSalesContainer>
        <QuarterSaleContainer>
          <QuarterSaleLabel>Salg Q1</QuarterSaleLabel>
          <QuarterSaleValue>{numberFormatterKrNoDec(quarterSales[1])}</QuarterSaleValue>
        </QuarterSaleContainer>
        <QuarterSaleContainer>
          <QuarterSaleLabel>Salg Q2</QuarterSaleLabel>
          <QuarterSaleValue>{numberFormatterKrNoDec(quarterSales[2])}</QuarterSaleValue>
        </QuarterSaleContainer>
        <QuarterSaleContainer>
          <QuarterSaleLabel>Salg Q3</QuarterSaleLabel>
          <QuarterSaleValue>{numberFormatterKrNoDec(quarterSales[3])}</QuarterSaleValue>
        </QuarterSaleContainer>
        <QuarterSaleContainer>
          <QuarterSaleLabel>Salg Q4</QuarterSaleLabel>
          <QuarterSaleValue>{numberFormatterKrNoDec(quarterSales[4])}</QuarterSaleValue>
        </QuarterSaleContainer>
      </QuarterSalesContainer>
      <TargetContainer>
        <img src={CrosshairImage} alt="crosshair" />
        <TargetValue>{numberFormatterKrNoDec(distanceFromGoal)}</TargetValue>
      </TargetContainer>
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 20%;
  position: relative;
  background-color: #1A3755;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  gap: 10px;
`;

const QuarterSalesContainer = styled.div`
  width: 70%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`
const QuarterSaleContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`
const QuarterSaleLabel = styled.div`
  color: white;
  font-size: 1em;
  font-weight: bold;
`
const QuarterSaleValue = styled.div`
  color: ${colors.teal};
`
const TargetContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 5px;
`
const TargetValue = styled.div`
  font-size: 1.5em;
  color: red;
  transform: translateY(5px);
`
const WeeklyTargetContainer = styled.div`
  font-size: 0.8em;
  display: flex;
  gap: 5px;
  & img {
    width: 20px;
  }
`
const WeeklyTargetValue = styled.div`
  color: white;
  transform: translateY(2px);
`
