import React from 'react'
import styled from 'styled-components'
import { useStore } from 'react-hookstore'
import { Bar } from 'react-chartjs-2'
import { ChartLabel } from './components'
import { tilbudsvolumoptions, colors } from './chartoptions'

export default ({ to, from, sales }) => {
  const [users] = useStore('users')

  let top3solgt = sales
    // eslint-disable-next-line
    .reduce((res, s) => {
      if (!res[s.responsible]) res[s.responsible] = {
        sum: 0,
        sales: [],
        user: users.find(u => u.id === s.responsible)
      }
      res[s.responsible].sales.push(s)
      res[s.responsible].sum += s.sum
      return res
    }, {})
  top3solgt = Object.values(top3solgt).sort((a,b) => {
    if (a.sum > b.sum) return -1
    if (a.sum < b.sum) return 1
    return 0
  })

  let labels = top3solgt.map(t => t.user.shortname)
  let dataset = top3solgt.map(t => t.sum) 

  const data = {
    labels, 
    datasets: [
      {
        data: dataset, 
        backgroundColor: colors.teal,
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.7)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        barPercentage: 0.4
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartLabel>Tilbudsvolum</ChartLabel>
      <StyledBar data={data} options={tilbudsvolumoptions} />
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 20%;
  position: relative;
`;

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`
