import React from 'react'
import styled from 'styled-components'
import { getYear, getWeek } from 'date-fns'
import { Bar } from 'react-chartjs-2'
import { ChartLabel, ChartImage } from './components'
import { baroptions, colors } from './chartoptions'
import { numberFormatterKrNoDec } from './../../shared/utils'
import CashImage from '../../shared/graphics/cash.png'

export default ({ from, to, sales, goal, hitrate }) => {

  const labels = new Array(52).fill(0).map((n,index) => index+1)
  const dbPerWeek = new Array(52).fill(null);
  const salesPerWeek = new Array(52).fill(0);
  const currentYear = new Date().getFullYear()

  sales.forEach(sale => {
    // eslint-disable-next-line
    if (sale.status != '6') return
    if (!sale?.completed) return
    const year = getYear(new Date(sale.completed))
    if (year === currentYear) {
      const weekNumber = getWeek(new Date(sale.completed)) - 1; // -1 to make it 0-indexed
      salesPerWeek[weekNumber] += sale.sum 
      if (sale.coverage) dbPerWeek[weekNumber] += (sale.coverage * sale.sum) / 100
    }
  })

  const weeklyTarget = goal/52

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Akkumulert omsetning justert etter hitrate',
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg, 
        data: salesPerWeek,
        order: 2,
      },
      {
        type: 'line',
        label: 'Dekningsbidrag',
        data: dbPerWeek,
        order: 1,
        borderWidth: 4,
        pointRadius: 7,
        pointHoverRadius: 7,
        pointHoverBorderWidth: 8,
        pointStyle: 'line', 
        pointBorderColor: 'red',
        backgroundColor: 'transparent',
      },
    ],
  };

  const options = Object.assign({
      onHover: (event, chartElement) => {
        const chart = event.chart;
        if (chartElement.length) {
          chart.data.datasets.forEach((dataset, i) => {
            dataset.datalabels = dataset.datalabels || {};
            dataset.datalabels.display = (context) => context.dataIndex === chartElement[0].index;
          });
        } else {
          chart.data.datasets.forEach((dataset) => {
            dataset.datalabels = dataset.datalabels || {};
            dataset.datalabels.display = false;
          });
        }
        chart.update();
      },
    }, baroptions, { plugins: Object.assign({}, baroptions.plugins, {
    annotation: {
      annotations: {
        line1: {
          // This defines a line annotation
          type: 'line',
          // Positioning the line
          yMin: weeklyTarget,
          yMax: weeklyTarget,
          borderColor: 'red',
          borderWidth: 2,
          borderDash: [10, 5],
          // If you want a label on the line
          label: {
            content: 'Expected Income',
            enabled: true,
            position: 'center',
            color: 'red'
          }
        }
      }
    },
    datalabels: {
      display: false,
      align: 'end',
      anchor: 'end',
      color: 'white',
      font: {
        size: 18,
      },
      formatter: (value, context) => {
        if (value === 0) return null
        return numberFormatterKrNoDec(value)
      },
    },
  })})

  return (
    <Container>
      <ChartLabel>Omsetning - Salg</ChartLabel>
      <ChartImage src={CashImage} />
      <StyledBar data={chartData} options={options} />
    </Container> 
  )
}

const Container = styled.div`
  width: 80%;
  position: relative;
`

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`

