import React, { useState } from 'react'
import { format } from '../../shared/utils'
import './Timeline.css'

// Helper to add days to a date
const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

// Helper to format dates as YYYY-MM-DD
export const formatTimelineDate = (date) => date.toISOString().split('T')[0];

// Generate a list of dates, starting from a specific date
const generateDates = (startDate, numDays) => {
  return Array.from({ length: numDays }, (_, i) => formatTimelineDate(addDays(startDate, i)));
};

const Timeline = ({ onDateChange, today }) => {
    const [startDate, setStartDate] = useState(() => addDays(today, -10));
    const [dates, setDates] = useState(() => generateDates(startDate, 30));
    const [selectedDate, setSelectedDate] = useState(() => formatTimelineDate(today));

    // Handle date click
    const handleDateClick = (date) => {
        if (onDateChange) onDateChange(date);
        setSelectedDate(date);
    };

    // Navigate backward by updating the start date
    const handlePreviousClick = () => {
        const newStartDate = addDays(startDate, -1); // Go back 30 days
        setStartDate(newStartDate);
        setDates(generateDates(newStartDate, 30)); // Re-generate 100 dates starting from the new start date
    };

    // Navigate forward by updating the start date
    const handleNextClick = () => {
        const newStartDate = addDays(startDate, 1); // Go forward 30 days
        setStartDate(newStartDate);
        setDates(generateDates(newStartDate, 30)); // Re-generate 100 dates starting from the new start date
    };

    return (
        <div className="timeline-container">
            <button className="nav-button" onClick={handlePreviousClick}>
                &lt; {/* Left arrow */}
            </button>
            <div className="timeline">
                {dates.map((date, index) => {
                const dayOfWeek = new Date(date).getDay()
                const sundayClass = dayOfWeek === 0 ? 'sunday' : ''
                const saturdayClass = dayOfWeek === 6 ? 'saturday' : ''
                const selectedClass = selectedDate === date ? 'selected' : ''
                const classNames = `timeline-item ${selectedClass} ${saturdayClass} ${sundayClass}`
                return (
                    <div
                        key={index}
                        className={classNames}
                        onClick={() => handleDateClick(date)}
                    >
                        {format(date, 'dd.MM')}
                    </div>
                )})}
            </div>
            <button className="nav-button" onClick={handleNextClick}>
                &gt; {/* Right arrow */}
            </button>
        </div>
    );
};

export default Timeline;

