import React from 'react'
import ReactDOM from 'react-dom'
import { useStore } from 'react-hookstore'
import { Router } from 'tiny-react-router'
import { ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { KviaTheme } from './shared/theme'
import TopBar from './shared/components/TopBar'
import Snackbar from './shared/components/Snackbar'
import Me from './screens/Me'
import Sale from './screens/Sale'
import Sales from './screens/Sales'
import Users from './screens/Users'
import Login from './screens/Login'
import EditUser from './screens/EditUser'
import './shared/state'
import './index.css'
import Missions from './screens/Missions'
import Customers from './screens/Customers'
import Dashboard from './screens/Dashboard'
import EditCustomer from './screens/EditCustomer'
import './setupSentry'

const routes = {
  '/'               : Dashboard,
  '/account'        : Me,
  '/login'          : Login,
  '/sales'          : Sales,
  '/contracts'      : Sales,
  '/users'          : Users,
  '/missions'       : Missions,
  '/users/add'      : EditUser,
  '/users/:id'      : EditUser,
  '/sales/:id'      : Sale,
  '/sales/add'      : Sale,
  '/customers'      : Customers,
  '/dashboard'      : Dashboard,
  '/customers/add'  : EditCustomer,
  '/customers/:id'  : EditCustomer,
}

const App = () => {
  let [dataloaded] = useStore('dataloaded')
  return (
    <div className="App">
      <ThemeProvider theme={KviaTheme}>
        <TopBar />
        {!dataloaded &&
          <div className="dataloaderContainer">
            <CircularProgress />
            <h3>Laster data...</h3>
          </div> 
        }
        {dataloaded &&
          <Router routes={routes} />
        }
        <Snackbar />
      </ThemeProvider>
    </div>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));
