import React from 'react'
import { nav, getPath } from 'tiny-react-router'
import { useStore } from 'react-hookstore'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { fetchSales } from '../../state' 
import { getFormField } from '../../formFields'

// TODO: user department filter on dashboard

export default ({ orderBy, clearOrderBy }) => {
  const [users] = useStore('users')
  const [status] = useStore('status')
  const [customers] = useStore('customers')
  const [departments] = useStore('departments')
  const [filters, setFilters] = useStore('filters')

  const path = getPath()
  let label = 'salg' // Switch based on path..
  if (path.startsWith('/contract')) label = 'rammeavtaler'

  const handleChange = (name, value, type) => event => {
    let val = event?.target?.value
    if (type === 'reset') val = value
    if (name === 'status') val = value
    if (name === 'status') {
      const has_all = value.find(v => v.id === -1)
      if (has_all) {
        val = status
      }
    }
    if (name === 'customer') val = value
    if (name === 'department') val = value
    if (name === 'department') {
      const has_all = value.find(v => v.id === -1)
      if (has_all) {
        val = departments
      }
    }
    if (name === 'responsible') val = value
    if (name === 'responsible') {
      const has_all = value.find(v => v.id === -1)
      if (has_all) {
        val = users
      }
    }
    setFilters({ ...filters, [name]: val });
  }

  const users_with_all = [{ id: -1, name: 'Alle' }].concat(users)
  const status_with_all = [{ id: -1, name: 'Alle' }].concat(status)
  const departments_with_all = [{ id: -1, name: 'Alle' }].concat(departments)

  const statusField = { name: 'status', label: 'Status', type: 'autoComplete', options: status_with_all, disabled: false }
  const StatusFilter = getFormField(statusField, filters, handleChange, null, 100, { adornment: true })
  const customerField = { name: 'customer', label: 'Kunde', type: 'autoComplete', options: customers, disabled: false }
  const CustomerFilter = getFormField(customerField, filters, handleChange, null, 100, { adornment: true })
  const departmentField = { name: 'department', label: 'Avdeling', type: 'autoComplete', options: departments_with_all, disabled: false }
  const DepartmentFilter = getFormField(departmentField, filters, handleChange, null, 100, { adornment: true })
  const responsibleField = { name: 'responsible', label: 'Ansvarlig', type: 'autoComplete', options: users_with_all, disabled: false }
  const ResponsibleFilter = getFormField(responsibleField, filters, handleChange, null, 100, { adornment: true })
  return (
    <FiltersContainer>
      <FilterRow>
        <StyledSearchIcon dim={(['/dashboard', '/', ''].indexOf(path) >= 0).toString()} />
        <StyledSearchFieldContainer dim={(['/dashboard', '/', ''].indexOf(path) >= 0).toString()}>
          <StyledSearchField
            id="standard-search"
            label={`Søk etter ${label}`}
            type="search"
            value={filters.saleSearchText}
            onChange={(e) => { setFilters(Object.assign({}, filters, { saleSearchText: e.target.value }))}}
            margin="normal"
          />
        </StyledSearchFieldContainer>
        {orderBy &&
          <>
          <StyledClearIcon onClick={clearOrderBy} /> 
          <NoSortText onClick={clearOrderBy}>Fjern sortering</NoSortText>
          </>
        }
        <Spacer />
        <StyledDateFilterContainer>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <StyleDatePicker
              variant="inline"
              format="dd-MM-yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Fra"
              value={filters.from}
              onChange={(date) => {
                setFilters(Object.assign({}, filters, { from: date })) 
                fetchSales()
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <StyleDatePicker
              variant="inline"
              format="dd-MM-yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Til"
              value={filters.to}
              onChange={(date) => { 
                setFilters(Object.assign({}, filters, { to: date })) 
                fetchSales()
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </StyledDateFilterContainer>
        { path.startsWith('/sales') &&
        <Fab color="primary" aria-label="add" onClick={() => nav('/sales/add')} >
          <AddIcon />
        </Fab>
        }
      </FilterRow>
      <FilterRow align="start" justify="space-between">
        <StyledFormFilter width={300} dim={(['/dashboard', '/', ''].indexOf(path) >= 0).toString()}>{StatusFilter}</StyledFormFilter> 
        <StyledFormFilter width={300}>{DepartmentFilter}</StyledFormFilter> 
        <StyledFormFilter width={300} dim={(['/dashboard', '/', ''].indexOf(path) >= 0).toString()}>{ResponsibleFilter}</StyledFormFilter> 
        <StyledFormFilter width={300} dim={(['/dashboard', '/', ''].indexOf(path) >= 0).toString()}>{CustomerFilter}</StyledFormFilter> 
      </FilterRow>
    </FiltersContainer>
  )
}

const paddingTop = 20

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
`
const FilterRow = styled.div`
  width: 100%;
  flex: auto;
  display: flex;
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'flex-start'};
  marign-bottom: 10px;
  gap: 10px;
`
const Spacer = styled.div`
  flex: auto;
`
const StyledSearchIcon = styled(SearchIcon)`
  padding-top: ${paddingTop}px;
  opacity: ${props => props.dim === 'true' ? '0.2' : '1'};
`
const StyleDatePicker = styled(KeyboardDatePicker)`
  margin-right: 10px;
  width: 150px;
`
const StyledSearchFieldContainer = styled.div`
  height: 75px;
  opacity: ${props => props.dim === 'true' ? '0.2' : '1'};
`
const StyledSearchField = styled(TextField)`
  padding-top: 0;
  margin-left: 5px; 
  margin-right: 5px; 
  width: 500px;
`
const StyledDateFilterContainer = styled.div`
  height: 75px;
`
const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer; 
  padding-top: ${paddingTop+15}px;
`
const NoSortText = styled.div`
  cursor: pointer; 
  font-size:1em;
  padding-top: ${paddingTop+15}px;
`

const StyledFormFilter = styled.div`
  flex: 1;
  opacity: ${props => props.dim === 'true' ? '0.2' : '1'};
  .formInput {
  }
`
