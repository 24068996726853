import React from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { ChartLabel } from './components'
import { rotateddualbaroptions, colors } from './chartoptions'

export default ({ to, from, sales }) => {

  const foresporsler = sales.length
  const tilbud = sales.filter(s => s.sum > 0).length
  const forhandlinger = sales.filter(s => ['5','6',5,6].indexOf(s.status) >= 0).length
  const sold = sales.filter(s => ['6',6].indexOf(s.status) >= 0).length
  const dataset = [foresporsler, tilbud, forhandlinger, sold] 

  const data = {
    labels: ['Forespørsler', 'Sendte tilbud', 'Forhandlinger', 'Salg'], 
    datasets: [
      {
        label: 'Labels',
        data: [0,0,0,0], 
        datalabels: {
          display: true,
          align: 'top',
          anchor: 'start',
          offset: 8,
          color: 'white',
          font: {
            size: 12,
            weight: 'bold',
          },
          formatter: (value, context) => {
            if (context.dataIndex === 0) return 'Forespørsler'
            if (context.dataIndex === 1) return 'Sendte tilbud'
            if (context.dataIndex === 2) return 'Forhandlinger'
            if (context.dataIndex === 3) return 'Salg'
            return value
          },
        },
      },
      {
        label: 'Left side',
        data: dataset.map(dp => -dp),
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg, 
        categoryPercentage: 0.4,
      },
      {
        label: 'Right side',
        data: dataset, 
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg, 
        categoryPercentage: 0.4,
      }
    ]
  };

  return (
    <ChartContainer>
      <ChartLabel>Konvertering</ChartLabel>
      <StyledBar data={data} options={rotateddualbaroptions} />
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 20%;
  position: relative;
`;

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`
