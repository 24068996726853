import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { FormControl, IconButton, InputLabel, Select, MenuItem, Button, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

export default function DepartmentRoleSelect({ state, field, handleChange }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (items.length > 0) return
    setItems(state.departments)
  }, [state, items.length])

  const handleAddItem = () => {
    setItems([...items, { department: '', role: '' }]);
  };

  const handleSelectChange = (index, selectKey, value) => {
    const updatedItems = items.map((item, i) => 
      i === index ? { ...item, [selectKey]: value } : item
    );
    setItems(updatedItems)
    handleChange('departments','departments')(updatedItems)
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index)
    setItems(updatedItems)
    handleChange('departments','departments')(updatedItems)
  }

  let departmentMenuItems = field.options.map(o => {
     return <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
  })
  let roleMenuItems = field.roles.map(o => {
     return <MenuItem key={o.id} value={o.id}>{o.role}</MenuItem>
  })

  return (
    <DepartmentContainer>
        {items.map((item, index) => (
          <Grid container spacing={1} key={index}>
            <Grid item xs={5}>
              <FormControl
                fullWidth
                key='department'
                variant="outlined" 
                className="formInput"
                required={field.required} 
                error={state[`${field.name}Error`]}>
                <InputLabel>Avdeling</InputLabel>
                <Select
                  value={item.department}
                  onChange={(e) => handleSelectChange(index, 'department', e.target.value)}
                >
                {departmentMenuItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl
                fullWidth
                key='role'
                variant="outlined" 
                className="formInput"
                required={field.required} 
                error={state[`${field.name}Error`]}>
                <InputLabel>Rolle</InputLabel>
                <Select
                  value={item.role}
                  onChange={(e) => handleSelectChange(index, 'role', e.target.value)}
                >
                {roleMenuItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                variant="contained"
                color="secondary"
                style={{ color: '#ccc' }}
                onClick={() => handleRemoveItem(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button variant="contained" color="primary" onClick={handleAddItem}>
          Legg til Avdeling 
        </Button>
    </DepartmentContainer>
  );
}

const DepartmentContainer = styled.div`
  padding-bottom: 10px;
`
