import React from 'react'
import styled from 'styled-components'
import { getYear } from 'date-fns'
import { Chart, registerables } from 'chart.js'
import { useStore } from 'react-hookstore'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import annotationPlugin from 'chartjs-plugin-annotation'
import TopSummary from './TopSummary'
import AntallSalg from './AntallSalg'
import Konvertering from './Konvertering'
import TilbudsVolum from './TilbudsVolum'
import SalgOmsetning from './SalgOmsetning'
import UavklarteTilbud from './UavklarteTilbud'
import OrdrereserveSum from './OrdrereserveSum'
import OmsetningPerSalg from './OmsetningPerSalg'
import SalgEtterSegment from './SalgEtterSegment'
import AntallForesporsler from './AntallForesporsler'
import AkkumulertOmsetning from './AkkumulertOmsetning'
import OmsetningTop3Selgere from './OmsetningTop3Selgere'
import OrdrereserveSalg12Mnd from './OrdrereserveSalg12mnd'
import OrdrereserveSalg48Mnd from './OrdrereserveSalg48mnd'
import ForesporslerEtterSegment from './ForesporslerEtterSegment'
import TilbudOgOmsetningEtterType from './TilbudOgOmsetningEtterType'
import TilbudOgOmsetningEtterSegment from './TilbudOgOmsetningEtterSegment'
import Filters from '../../shared/components/Filters'
Chart.register(...registerables, ChartDataLabels, annotationPlugin)

export default() => {
  const [filters] = useStore('filters')
  const [allsales] = useStore('sales')
  const [departments] = useStore('departments')

  const to = filters.to
  const from = filters.from

  // Filtrere ut salg med periodisering som har avklart et annet år (da skal de ikke vise på salgs grafene) 
  const fromYear = getYear(from)
  let sales = allsales.filter(s => {
    if (s.contractperiod && getYear(new Date(s.completed)) !== fromYear) return false
    return true
  })
  // Alle med periodisering
  let order_reserves = allsales.filter(s => {
    return s.contractperiod
  })

  // Filter basert på avdelingsfilter
  if (filters.department.length > 0) {
    sales = sales.filter(s => filters.department.map(d => d.id).indexOf(s.department) >= 0)
    order_reserves = order_reserves.filter(s => filters.department.map(d => d.id).indexOf(s.department) >= 0)
  }
  const relevante_departments = filters.department.length > 0 ? departments.filter(d => filters.department.map(fd => fd.id).includes(d.id)) : departments

  const sum = sales.reduce((sum,s) => sum+s.sum, 0)
  const goal = relevante_departments.reduce((s,d) => s+d.salesgoal, 0) 
  const average_sum = (sum / sales.length)
  // eslint-disable-next-line
  const tapt = sales.filter(s => s.status == '5' && !!s?.completed)
  // eslint-disable-next-line
  const solgt = sales.filter(s => s.status == '6' && !!s?.completed)
  const tapt_sum = tapt.reduce((sum,s) => sum+s.sum, 0)
  const solgt_sum = solgt.reduce((sum,s) => sum+s.sum, 0)
  const average_sum_solgt = (solgt_sum / solgt.length)
  let hitrate = 0
  try { hitrate = ((solgt_sum / (solgt_sum+tapt_sum)) * 100).toFixed(0) } catch(e) {}

  return (
    <DashContainer>
      <FiltersContainer>
        <Filters /> 
      </FiltersContainer>
      <DashContainerInner>
        <TopSummary to={to} from={from} sales={sales} goal={goal} />
        <ChartRow>
          <AntallForesporsler to={to} from={from} sales={sales} />
          <ForesporslerEtterSegment to={to} from={from} sales={sales} />
        </ChartRow>
        <ChartRow>
          <AkkumulertOmsetning to={to} from={from} sales={sales} average_sum={average_sum} hitrate={hitrate} />
          <Konvertering to={to} from={from} sales={sales} />
        </ChartRow>
        <ChartRow>
          <AntallSalg to={to} from={from} sales={sales} />
          <SalgEtterSegment to={to} from={from} sales={sales} />
        </ChartRow>
        <ChartRow>
          <SalgOmsetning to={to} from={from} sales={sales} goal={goal} average_sum={average_sum_solgt} hitrate={hitrate} />
          <OmsetningPerSalg to={to} from={from} sales={sales} goal={goal} average_sum={average_sum_solgt} />
        </ChartRow>
        <ChartRow minheight={400}>
          <TilbudOgOmsetningEtterSegment to={to} from={from} sales={sales} average_sum={average_sum_solgt} hitrate={hitrate} />
          <TilbudOgOmsetningEtterType to={to} from={from} sales={sales} average_sum={average_sum_solgt} hitrate={hitrate} />
          <UavklarteTilbud to={to} from={from} sales={sales} average_sum={average_sum_solgt} />
        </ChartRow>
        <ChartRow minheight={300}>
          <OmsetningTop3Selgere to={to} from={from} sales={sales} />
          <TilbudsVolum to={to} from={from} sales={sales} />
        </ChartRow>
        <ChartRow minheight={300}>
          <OrdrereserveSalg12Mnd to={to} from={from} sales={sales} order_reserves={order_reserves} />
          <OrdrereserveSalg48Mnd to={to} from={from} sales={sales} order_reserves={order_reserves} />
          <OrdrereserveSum to={to} from={from} sales={sales} order_reserves={order_reserves} />
        </ChartRow>
      </DashContainerInner>
    </DashContainer>
  )
}

const maxWidth = 1500

const DashContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  font-family: Aptos;
`

const DashContainerInner = styled.div`
  width: 100%;
  max-width: ${maxWidth}px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  gap: 10px;
`

const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: ${props => props.minheight || '220'}px;
  gap: 10px;
`

const FiltersContainer = styled.div`
  width: 100%;
  max-width: ${maxWidth}px;
`
