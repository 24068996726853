import React, { useState, useEffect } from 'react'
import { nav } from 'tiny-react-router'
import { useStore } from 'react-hookstore'
import Button from '@material-ui/core/Button'
import { getFormField } from '../../shared/formFields'
import { format, numberFormatterKrNoDec } from '../../shared/utils'
import { DG_MIN_POLICY } from '../../shared/config'
import './index.css'

export default (props) => {
  const creating = !props.id
  const [sale, setSale] = useState(null)
  const [sales, setSales] = useStore('sales')
  const [writer, setWriter] = useState(false)
  const [change, setChange] = useState(false)
  const [type] = useStore('type')
  const [user,] = useStore('user') 
  const [users,] = useStore('users')  
  const [segment] = useStore('segment')
  const [statuses] = useStore('status')
  const [customers, setCustomers] = useStore('customers')
  const [departments] = useStore('departments')
  // eslint-disable-next-line
  const [snackbar, setSnackbar] = useStore('snackbar')
  const [clientoffers, setClientOffsers] = useState([])

  const addNewCustomer = async (name) => {
    const res = await fetch(`/api/customer`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name })
    })
    if (!res.ok) return setSnackbar({ open: true, message: 'Kunne ikke opprette kunde', variant: 'error', showtime: 3000 })
    setSnackbar({ open: true, message: 'Kunne opprettet', variant: 'success', showtime: 3000 })
    const customer = await res.json()
    const _customers = [customer].concat(customers)
    setCustomers(_customers)
    setFields(currentFields => {
      let updatedFields = currentFields.map(f => {
        if (f.name === 'customer') f.options = _customers
        return f
      })
      return updatedFields
    })
    setState(currentState => {
      return { ...currentState, customer }
    })
    setChange(true)
  }

  let field = [
    { name: 'name',           label: 'Prosjektnavn',     type: 'text', required: true, disabled: false },
    { name: 'customer',       label: 'Kunde',            type: 'singleAutoComplete', options: customers, required: true, disabled: false, addOnMissing: true, handleAddOnMissing: addNewCustomer },
    { name: 'status',         label: 'Status',           type: 'select', options: statuses, required: true, disabled: false },
    { name: 'sum',            label: 'Sum',              type: 'number', disabled: false, valueFormatter: numberFormatterKrNoDec },
    { name: 'responsible',    label: 'Ansvarlig',        type: 'select', options: users, required: true, disabled: false },
    { name: 'department',     label: 'Avdeling',         type: 'select', options: departments,required: true, disabled: false  },
    { name: 'activity',       label: 'Kontaktperson',    type: 'text', disabled: false },
    { name: 'superoffice',    label: 'Referanse',        type: 'text', disabled: false },
    { name: 'received',       label: 'Mottatt',          type: 'date', required: true, disabled: false },
    { name: 'deadline',       label: 'Frist',            type: 'date', disabled: false },
    { name: 'delivered',      label: 'Levert',           type: 'date', disabled: false },
    { name: 'completed',      label: 'Avklart',          type: 'date', disabled: false },
    { name: 'startup',        label: 'Oppstart',         type: 'date', disabled: false },
    { name: 'notes',          label: 'Notat',            type: 'textarea', disabled: false },
    { name: 'attachments',    label: 'Attachments',      type: 'file' },
    { name: 'customers',      label: 'Interessenter',    type: 'autoComplete', options: customers,  defaultValue: [], disabled: false },
    { name: 'segment',        label: 'Segment',          type: 'select', options: segment, required: true, disabled: false },
    { name: 'type',           label: 'Type',             type: 'select', options: type, required: true, disabled: false },
    { name: 'coverage',       label: 'Dekningsgrad (%)', type: 'number', disabled: false, defaultValue: null },
    { name: 'coveragecalc',   label: 'Dekningsbidrag',   type: 'number', disabled: true, readOnly: true, defaultValue: null, valueFormatter: numberFormatterKrNoDec },
    { name: 'contractperiod', label: 'Utførelsestid (mnd)',    type: 'number', disabled: false, defaultValue: null },
    { name: 'contract',       label: 'Rammeavtale',      type: 'checkbox', disabled: false, defaultValue: false },
  ]
  const [fields, setFields] = useState(field)
  let fieldState = fields.reduce((_state, field) => {
    _state[field.name] = field.defaultValue !== undefined ? field.defaultValue : ''
    _state[`${field.name}Error`] = false
    _state[`${field.name}HelperText`] = false
    return _state
  }, {})
  const [state, setState] = useState(fieldState)
  const [dg_policy_warning, set_dg_policy_warning] = useState(0)

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  useEffect(() => {
    if (!inputLabel?.current) return
    setLabelWidth(inputLabel.current.offsetWidth);
  }, [])

  useEffect(() => {
    if (creating) return
    const set_or_fetch = async () => {
      // eslint-disable-next-line
      let sale = sales.find(p => p.id == props.id)
      if (!sale) {
        // NOTE: If we cannot find sale in sales we try to fetch it
        const sale_res = await fetch(`/api/sales/${props.id}`)
        if (sale_res.ok) {
          sale = await sale_res.json()  
          const _sales = [sale].concat(sales)
          setSales(_sales)
        }
      }
      setSale(sale)
      if (sale == null) return // NOTE: Cannot set fields if sale is null
      setState(state => Object.assign({}, state, sale, {
        received: format(sale.received, 'yyyy-MM-dd'),
        deadline: format(sale.deadline, 'yyyy-MM-dd'),
        delivered: format(sale.delivered, 'yyyy-MM-dd'),
        startup: format(sale.startup, 'yyyy-MM-dd'),
        completed: format(sale.completed, 'yyyy-MM-dd'),
        customers: customers.filter(i => sale.customers.includes(i.id)),
        customer: customers.filter(i => i.id === sale.customer)[0],
        coveragecalc: (sale.coverage * sale.sum) / 100
      }))
    }
    set_or_fetch()
  }, [sale, sales, props.id, creating, customers, user, setSales])

  useEffect(() => {
    if (creating) return
    const fetch_client_other_offers = async () => {
      // eslint-disable-next-line
      let sale = sales.find(p => p.id == props.id)
      if (!sale) return
      const clientoffers_res = await fetch(`/api/sales/${sale.id}/clientoffers`)
      if (clientoffers_res.ok) {
        const clientoffers = await clientoffers_res.json()  
        setClientOffsers(clientoffers)
      }
    }
    fetch_client_other_offers()
  }, [sale, creating, props.id, sales])

  useEffect(() => {
    if (!user) return
    let write = user.administrator 
    if (sale) {
      const department = user.departments.find(d => d.id === sale.department)
      if (department && department.role_id < 3) write = true
      if (sale.responsible === user.id) write = true
      setFields(currentFields => {
        let updatedFields = currentFields.map(f => {
            let d = !write
            if (f.readOnly) d = true
            return { ...f, disabled: d };
        })
        return updatedFields
      })
    }
    if (creating) write = true
    setWriter(write)
  }, [user, sale, setWriter, creating])

  const handleChange = (name, value, type) => (event, newval) => {
    let val = event?.target?.value
    if (type === 'file') val = value
    if (type === 'number') val = value
    if (type === 'checkbox') val = event.target.checked
    if (name === 'progress') val = newval
    if (name === 'customer') val = value
    if (name === 'customers') val = value
    if (name === 'attachments') val = event
    setState({ ...state, [name]: val });
    setChange(true)
  }
  const handleSave = async () => {
    if (creating) save(true)
    else save()
  }
  const handleCancel = async () => {
    let path = '/sales'
    if (sale?.contract) path = '/contracts' 
    if (change) {
      let confirmed = window.confirm('Du har endringer, er du sikker på at du vil navigere?')
      if (!confirmed) return
    }
    nav(path)
  }
  const handleDelete = async () => {
    let confirmed = window.confirm('Sikker? Sletting er endelig')
    if (!confirmed) return
    remove()
  }
  const handleUploadFile = async (file) => {
    if (!state.id) return setSnackbar({ open: true, message: 'Du må lagre salget før du kan legge til vedlegg', variant: 'warning', showtime: 3000 })
    const formData = new FormData();
    formData.append('file', file);
    const res = await fetch(`/api/sales/${props.id}/files`, {
      method: 'POST',
      body: formData,
    })
    if (!res.ok) alert('Feil ved filopplasting')
    const new_file = await res.json()
    const attachments = state.attachments || []
    attachments.push(new_file)
    setState({ ...state, attachments });
  }

  const handleDownloadFile = (fileToDownload) => {
    window.open(`/api/sales/${props.id}/files/${fileToDownload.s3filename}`, '_blank')
  }

  const checkErrors = () => {
    let errors = {}
    fields.forEach(field => {
      if (field.required) {
        if ([null, '',].indexOf(state[field.name]) >= 0) {
          let _error = {}
          _error[`${field.name}Error`] = true
          _error[`${field.name}HelperText`] = `${field.label} er påkrevd`
          Object.assign(errors, _error) 
        } else {
          let _error = {}
          _error[`${field.name}Error`] = false
          _error[`${field.name}HelperText`] = '' 
          Object.assign(errors, _error) 
        }
      }
    })
    setState(Object.assign({}, state, errors))
    let hasErrors = Object.keys(errors).reduce((sum, key) => { if (sum) return sum; return errors[key] },false)
    return hasErrors
  }

  const checkConditions = () =>  {
    let missing_condition = false
    const new_status = state.status
    const current_status = sale?.status
    // eslint-disable-next-line
    if (new_status != current_status) {
      // eslint-disable-next-line
      if (new_status == 6) {
        // Vunnet - krever dato for salg (avklart?), dato for oppstart samt utførelsestid 
        const errors = {}
        if (!state.startup) { missing_condition = true; errors.startupError = true }
        if (!state.completed) { missing_condition = true; errors.completedError = true }
        if (!state.contractperiod) { missing_condition = true; errors.contractperiodError = true }
        if (missing_condition) {
          setSnackbar({ open: true, message: 'For å kunne sette status Vunnet må følgende også settes; Avklart, Oppstart og Utførelsestid.', variant: 'warning', showtime: 5000 })
          setState({ ...state, ...errors })
          setTimeout(() => {
            const _errors = { startupError: false, completedError: false, contractperiodError: false }
            setState({ ...state, ..._errors })
          }, 1500)
          return missing_condition
        }
      }
      // eslint-disable-next-line
      if (new_status == 7) {
        // Tapt - krever avklart dato og årsak i kommentarfelt 
        const errors = {}
        if (!state.completed) { missing_condition = true; errors.completedError = true }
        // eslint-disable-next-line
        if (state?.notes == sale?.notes) { missing_condition = true; errors.notesError = true }
        if (missing_condition) {
          setSnackbar({ open: true, message: 'For å kunne sette status Tapt må følgende også settes; Avklart og oppdatert Notat med årsak.', variant: 'warning', showtime: 5000 })
          setState({ ...state, ...errors })
          setTimeout(() => {
            const _errors = { startupError: false, notesError: false }
            setState({ ...state, ..._errors })
          }, 1500)
          return missing_condition
        }
      }
    }
    const new_coverage = state.coverage
    const current_coverage = sale?.coverage
    // eslint-disable-next-line
    if (new_coverage != current_coverage && dg_policy_warning < 1) {
      const errors = {}
      if (new_coverage < DG_MIN_POLICY) { missing_condition = true; errors.coverageError = true }
      if (missing_condition) {
        setSnackbar({ open: true, message: `Dekningsgrad bør være minimum ${DG_MIN_POLICY}%. Lagre igjen for å sette likevel.`, variant: 'warning', showtime: 5000 })
        setState({ ...state, ...errors })
        set_dg_policy_warning(1)
        setTimeout(() => {
          const _errors = { coverageError: false }
          setState({ ...state, ..._errors })
        }, 1500)
        return missing_condition
      }
    }
    return missing_condition 
  }

  const save = async (create = false) => {
    let hasErrors = checkErrors()
    if (hasErrors) return
    let hasConditions = checkConditions()
    if (hasConditions) return

    let payload = fields.reduce((col, field) => {
      col[field.name] = state[field.name];
      return col;
    }, {}); 
    let url = create ? '/api/sales' : `/api/sales/${props.id}`
    if (!create) delete payload.id
    delete payload.coveragecalc
    let res = await fetch(url, 
      { 
        method: create ? 'POST' : 'PUT', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(Object.assign(payload, {
          received:  format(payload.received, 'yyyy-MM-dd:12:00', null),
          deadline:  format(payload.deadline, 'yyyy-MM-dd:12:00', null),
          delivered: format(payload.delivered, 'yyyy-MM-dd:12:00', null),
          startup:   format(payload.startup, 'yyyy-MM-dd:12:00', null),
          completed: format(payload.completed, 'yyyy-MM-dd 12:00', null),
          customer:  payload.customer?.id || null,
          customers: payload.customers !== '' ? JSON.stringify(payload.customers.map(c => c.id)) : [],
          sum:       payload.sum !== '' ? payload.sum : null,
          attachments : JSON.stringify(payload.attachments || []),
        }))
      })
    if (!res.ok) return await handleAPIErrors(res)
    let sale = await res.json()
    let _sales, message;
    if (create) {
      _sales = [sale].concat(sales)
      message = 'Salg opprettet'
    }
    else {
      // eslint-disable-next-line
      _sales = sales.map(s => s.id == props.id ? sale : s)
      message = 'Salg oppdatert'
    }
    setSales(_sales)
    setChange(false)
    setSnackbar({ open: true, message: message, variant: 'success', showtime: 3000 })
    if (create) nav(`/sales/${sale.id}`)
  }



  const remove = async () => {
    let res = await fetch(`/api/sales/${props.id}`, {
      method: 'DELETE'
    })
    if (!res.ok) return await handleAPIErrors(res)
    // eslint-disable-next-line
    let _sales = sales.filter(s => s.id != props.id)
    setSales(_sales)
    setSnackbar({ open: true, message: 'Salg slettet', variant: 'warning', showtime: 3000 })
    setTimeout(() => { nav(`/sales`) })
  }

  const handleAPIErrors = async (res) => {
    let code = typeof res.text === 'function' ? await res.text() : null
    if (code === '23505') code = 'id allerede i bruk'
    return setSnackbar({ open: true, message: `Feil ved lagring av salg: ${code}`, variant: 'error', showtime: 3000 })
  }

  let formFields1 = fields.filter(f => ['name','customer','status','sum', 'segment'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields2 = fields.filter(f => ['responsible','department','activity','superoffice', 'type'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields3 = fields.filter(f => ['received', 'deadline', 'delivered', 'completed', 'startup'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields4 = fields.filter(f => ['notes' , 'attachments'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth, { handleUploadFile, handleDownloadFile })
  })
  let formFields5 = fields.filter(f => ['customers', 'contract'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields6 = fields.filter(f => ['coverage', 'coveragecalc', 'contractperiod'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })

  let changelog = []
  if (sale?.changelog) changelog = sale.changelog.map((entry,i) => {
    const changes = Object.keys(entry).filter(k => k !== 'diffmeta').map(k => {
      let field = k
      let prev = entry[k].prev
      let next = entry[k].next
      if (field === 'segment') {
        // eslint-disable-next-line
        prev = segment.find(s => s.id == prev)?.name
        // eslint-disable-next-line
        next = segment.find(s => s.id == next)?.name
      } 
      if (field === 'type') {
        // eslint-disable-next-line
        prev = type.find(s => s.id == prev)?.name
        // eslint-disable-next-line
        next = type.find(s => s.id == next)?.name
      } 
      if (field === 'responsible') {
        // eslint-disable-next-line
        prev = users.find(s => s.id == prev)?.name
        // eslint-disable-next-line
        next = users.find(s => s.id == next)?.name
      } 
      if (field === 'status') {
        // eslint-disable-next-line
        prev = statuses.find(s => s.id == prev)?.name
        // eslint-disable-next-line
        next = statuses.find(s => s.id == next)?.name
      } 
      if (field === 'customer') {
        // eslint-disable-next-line
        prev = customers.find(s => s.id == prev)?.name
        // eslint-disable-next-line
        next = customers.find(s => s.id == next)?.name
      } 
      if (field === 'department') {
        // eslint-disable-next-line
        prev = departments.find(s => s.id == prev)?.name
        // eslint-disable-next-line
        next = departments.find(s => s.id == next)?.name
      } 
      if (['received', 'deadline', 'delivered', 'completed', 'startup'].indexOf(field) >= 0) {
        // eslint-disable-next-line
        prev = format(prev, 'dd.MM.yyyy')
        // eslint-disable-next-line
        next = format(next, 'dd.MM.yyyy')
      } 
      if (field === 'customers') {
        // eslint-disable-next-line
        prev = prev.map(cid => customers.find(c => c.id == cid)?.name).join(', ')
        // eslint-disable-next-line
        next = next.map(cid => customers.find(c => c.id == cid)?.name).join(', ')
      } 
      if (field === 'progress') {
        if (prev === null) prev = 0
        if (next === null) next = 0
        prev = `${prev}%` 
        next = `${next}%` 
      } 
      if (typeof(prev) === 'boolean') prev = prev ? 'Ja' : 'Nei'
      if (typeof(next) === 'boolean') next = next ? 'Ja' : 'Nei'

      if (field === 'sum') {
        prev = numberFormatterKrNoDec(prev)
        next = numberFormatterKrNoDec(next)
      }

      if (field === 'notes') field = 'Notat'
      if (field === 'startup') field = 'Oppstart'
      if (field === 'received') field = 'Mottatt'
      if (field === 'deadline') field = 'Frist'
      if (field === 'customer') field = 'Kunde'
      if (field === 'activity') field = 'Aktivitet'
      if (field === 'contract') field = 'Rammeavtale'
      if (field === 'coverage') field = 'Dekningsgrad'
      if (field === 'progress') field = 'Fremdrift'
      if (field === 'customers') field = 'Interessenter'
      if (field === 'completed') field = 'Avklart'
      if (field === 'delivered') field = 'Levert'
      if (field === 'department') field = 'Avdeling'
      if (field === 'attachments') field = 'Vedlegg'
      if (field === 'superoffice') field = 'Referanse'
      if (field === 'responsible') field = 'Ansvarlig'
      if (field === 'contractperiod') field = 'Utførelsestid'

      return (
        <div className="statusUpdate" key={k}>
          { ['Vedlegg','Notat'].indexOf(field) < 0 &&
          <>
            <div className="key">{field} endret fra</div>
            <div className="detail">{prev}</div> 
            <div>{`->`}</div> 
            <div className="detail">{next}</div>
            <div className="spacer" />
          </>
          }
          { ['Notat'].indexOf(field) >= 0 && next &&
          <>
            <NotesDiffComponent field={field} next={next} prev={prev} /> 
          </>
          }
          { ['Vedlegg'].indexOf(field) >= 0 && next &&
          <>
            <div className="key">{field} lagt til:</div>
            <div className="detail">{next.map(f => f.filename).join(', ')}</div>
            <div className="spacer" />
          </>
          }
          { ['Vedlegg'].indexOf(field) >= 0 && prev &&
          <>
            <div className="key">{field} fjernet:</div>
            <div className="detail">{prev.map(f => f.filename).join(', ')}</div>
            <div className="spacer" />
          </>
          }
        </div>
      )
    })

    const user = users.find(u => u.id === entry.diffmeta.user)
    const changetime = format(entry.diffmeta.timestamp, 'dd.MM.yyyy HH:mm')

    return (
      <div className="changelog" key={i}>
        <div className="diffmeta">
          <div className="user">{user?.name}</div>
          <div className="timestamp">{changetime}</div>
        </div>
        {changes}
      </div>
    )
  })

  const clientOfferListItems = clientoffers.map(sale => {
    return (
      <div key={sale.id} className="clientOfferListItem">
        <a href={`/#/sales/${sale.id}`}>{sale.name}</a>
      </div>
    )
  })

  if (!creating && !sale) {
    return (
      <div className="Sale">
        <div className="heading">
          <h1 className="title">Salg ikke funnet</h1>
        </div>
      </div>
    )
  }

  return (
    <div className="Sale">
      <div className="heading">
        <h1 className="title">{creating ? 'Opprett' : 'Rediger'} salg</h1>
        <div className="spacer" />
        <div className="timestamps">
          <div className="timestamp">{`Opprettet: ${format(state.created_at, 'dd.MM.yyyy HH:mm')}`}</div>
          <div className="timestamp">{`Sist endret: ${format(state.updated_at, 'dd.MM.yyyy HH:mm')}`}</div>
        </div>
      </div>

      <form noValidate autoComplete="off">
        <div className="formRow">
          <div className="formColumn">
            {formFields1}
          </div>
          <div className="formColumn">
            {formFields2}
          </div>
          <div className="formColumn">
            {formFields3}
          </div>
        </div>
        <div className="formRow">
          <div className="formColumnCoverage">
            {formFields6}
          </div>
        </div>
        <div className="formRow">
          <div className="formColumnWide">
            {formFields4}
          </div>
          <div className="formColumn">
            {formFields5}
          </div>
        </div>
      </form>

      <div className="buttonsContainer">
        { writer &&
        <Button variant="contained" color="primary" onClick={handleSave}>Lagre</Button>
        }
        <Button variant="contained" color="secondary" onClick={handleCancel}>Tilbake</Button>
        <div className="spacer" />
        {!creating && writer && 
          <Button variant="contained" color="secondary" onClick={handleDelete}>Slett</Button>
        }
      </div>

      <div className="metaContainer"> 
        <div className="activityContainer">
          <h3>Aktivitetslogg:</h3>
          <div className="changelogContainer">
            {changelog}
          </div>
        </div>
        <div className="activityContainer offers">
          <h3>Tilbud kunde:</h3>
          <div className="clientOfferContainer">
            {clientOfferListItems}
          </div>
        </div>
      </div>
    </div>
  )
}

const NotesDiffComponent = ({ field, prev, next }) => {
  // State to control whether details are shown
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="notesDiffContainer">
      <div className="label">
        <div className="key">{field} endret</div>
        <div className="showChanges" onClick={toggleVisibility}>
          {isVisible ? '(skjul endringer)' : '(se endringer)'}
        </div>
      </div>
      <div className={isVisible ? 'visible' : 'hidden'}>
        <div className="changes">{prev}</div>
        <div className="changes">{next}</div>
      </div>
      <div className="spacer" />
    </div>
  );
};

