import { startOfYear } from 'date-fns'
import { createStore } from 'react-hookstore'
import { format } from 'date-fns'
import { nav } from 'tiny-react-router'

let initialFromDate = startOfYear(new Date())

// eslint-disable-next-line
const dataLoadStore = createStore('dataloaded', false)
// eslint-disable-next-line
const salesLoadStore = createStore('salesloading', false)
// eslint-disable-next-line
const salesStore = createStore('sales', [])
// eslint-disable-next-line
const statusStore = createStore('status', [
  { id: 1, name: "Lead", percentage: 10, color: '#04ECD6' }, 
  { id: 2, name: "Forespørsel", percentage: 30, color: '#04ECD6' }, 
  { id: 3, name: "Tilbud sendt", percentage: 50, color: '#04ECD6' }, 
  { id: 4, name: "Innledende forhandling", percentage: 70, color: '#04ECD6' }, 
  { id: 5, name: "Sluttforhandling", percentage: 90, color: '#04ECD6' }, 
  { id: 6, name: "Vunnet", percentage: 100, color: '#00B050' }, 
  { id: 7, name: "Tapt", percentage: 100, color: '#FF0000' },
  { id: 8, name: "Ikke levert", percentage: 0, color: '' },
  { id: 9, name: "Kansellert", percentage: 0, color: '' },
])
// eslint-disable-next-line
const segmentStore = createStore('segment', [
  { id: 1, name: "Privat" }, 
  { id: 2, name: "Offentlig" }, 
  { id: 3, name: "Entreprenør" }, 
  { id: 4, name: "Industri" }
])
// eslint-disable-next-line
const typeStore = createStore('type', [
  { id: 1, name: "Vakuumgraving" }, 
  { id: 2, name: "Rørinspeksjon" }, 
  { id: 3, name: "Rørspyling" }, 
  { id: 4, name: "RFP" }, 
  { id: 5, name: "RFO" }, 
  { id: 6, name: "Slamsuging" }, 
  { id: 7, name: "Tørrsug - Supersuger" }, 
  { id: 8, name: "Va-Anlegg" },
  { id: 9, name: "Tømmeavtale" },
  { id: 10, name: "ADR" },
  { id: 11, name: "Industri" },
  { id: 12, name: "Kran" }
])
// eslint-disable-next-line
const filterStore = createStore('filters', {
  userSearchText     : '',
  saleSearchText     : '',
  customerSearchText : '',
  from               : new Date(initialFromDate),
  to                 : new Date(),
  orderBy            : null,
  orderByDirection   : 'asc',  
  responsible        : [],
  customer           : [],
  status             : [],
  department         : []
})
// eslint-disable-next-line
const snackStore = createStore('snackbar', { open: false, message: '', variant: 'success', showtime: 3000 })
// eslint-disable-next-line
const userStore = createStore('user', null) 
// eslint-disable-next-line
const roleStore = createStore('roles', [])
const usersStore = createStore('users', []) 
const operatorStore = createStore('operators', [])
const customerStore = createStore('customers', [])
const departmentStore = createStore('departments', [])

/*
   "StatusListe"   : ["Forespørsel", "Kalkulasjon", "Aktiv", "Uavklart", "Tapt", "Solgt", "Ikke levert (alt annet her - lik som Tapt)"],
*/

;

export async function fetchSales() {
  salesLoadStore.setState(true)
  let filters = filterStore.getState()
  let sales = await fetch(`/api/sales?from=${format(filters.from, 'yyyy-MM-dd')}&to=${format(filters.to, 'yyyy-MM-dd')}:24:00`)
    .then(res => res.json())
  salesStore.setState(sales)
  salesLoadStore.setState(false)
}

export async function fetchUsers() {
  const users_res = await fetch(`/api/users`)
  if (users_res.status !== 200) return usersStore.setState([])
  const users = await users_res.json()
  usersStore.setState(users)
}

export async function fetchDepartments() {
  const dept_res = await fetch(`/api/dept`)
  if (dept_res.status !== 200) return departmentStore.setState([])
  const departments = await dept_res.json()
  departmentStore.setState(departments)
}

export async function fetchOperators() {
  const cust_res = await fetch(`/api/operators`)
  if (cust_res.status !== 200) return operatorStore.setState([])
  const operators = await cust_res.json()
  operatorStore.setState(operators)
}

export async function fetchCustomers() {
  const cust_res = await fetch(`/api/customer`)
  if (cust_res.status !== 200) return customerStore.setState([])
  const customers = await cust_res.json()
  customerStore.setState(customers)
}

export async function fetchRoles() {
  const role_res = await fetch(`/api/role`)
  if (role_res.status !== 200) return roleStore.setState([])
  const roles = await role_res.json()
  roleStore.setState(roles)
}

export async function init() {
  let me_res = await fetch('/api/me')
  if (me_res.status !== 200) {
    setTimeout(() => dataLoadStore.setState(true), 200)
    return nav('/login')
  }
  let me = await me_res.json()
  userStore.setState(me)
  await fetchSales()
  await fetchUsers()
  await fetchDepartments()
  await fetchCustomers()
  await fetchRoles()
  await fetchOperators()
  dataLoadStore.setState(true)
}

/** INIT **/

(async () => {
  await init()
})()
