import React from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { useStore } from 'react-hookstore'
import { ChartLabel } from './components'
import { colors, rotateddoublebaroptionstype, createDiagonalPattern } from './chartoptions'
import { numberFormatterKrNoDec } from './../../shared/utils'
import BarsImage from '../../shared/graphics/bars.png'

export default ({ to, from, sales }) => {
  const [types] = useStore('type')

  const type_all_sums = {}
  const type_solgt_sums = {}
  const type_ids = sales
    .reduce((labels, s) => {
      if (labels.indexOf(s.type) < 0) {
        labels.push(s.type)
        type_all_sums[s.type] = 0
        type_solgt_sums[s.type] = 0
      }
      type_all_sums[s.type] += s.sum
      // eslint-disable-next-line
      if (s.status == '6') {
        type_solgt_sums[s.type] += s.sum
      }
      return labels
    }, [])

  // eslint-disable-next-line
  const found_types = type_ids.map(sid => types.find(s => s.id == sid))
    .sort((a,b) => {
      if (type_all_sums[a.id] > type_all_sums[b.id]) return -1
      if (type_all_sums[a.id] < type_all_sums[b.id]) return 1
      return 0
    })

  const dataset_all = found_types.map(fs => type_all_sums[fs.id]) 
  const dataset_solgt = found_types.map(fs => type_solgt_sums[fs.id]) 
  const dataset_largest = new Array(dataset_all.length).fill(Math.max(...dataset_all))
  const dataset_largest_max = (dataset_largest[0] || 0) + 0.1 * (dataset_largest[0] || 0)
  const dataset_percentage = found_types.map(fs => {
    const total = type_all_sums[fs.id];
    const solgt = type_solgt_sums[fs.id];
    return total > 0 ? (solgt / total) * 100 : 0; // Checks to ensure no division by zero
  })

  const data = {
    labels: found_types.map(s => s.name), 
    datasets: [
      {
        data: dataset_all, 
        backgroundColor: createDiagonalPattern({ color: 'white', fill: 'rgb(75,192,192)' }),
        borderWidth: 1,
        barPercentage: 1, // Adjust the width of each bar within its category slot
        datalabels: {
          display: false,
        },
      },
      {
        data: dataset_largest, 
        type: 'line',
        backgroundColor: 'rgba(0,0,0,0)', // Make the bar background transparent
        borderColor: 'rgba(0,0,0,0)', // Make the bar border transparent
        hoverBackgroundColor: 'rgba(0,0,0,0)', // Make the hover background transparent
        hoverBorderColor: 'rgba(0,0,0,0)', // Make the hover border transparent
        barPercentage: 0,
        categoryPercentage: 0,
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'right',
          textAlign: 'right',
          color: 'white',
          padding: { 
            top: 30,
            right: 30 
          },
          font: {
            size: 14,
          },
          formatter: (value, context) => {
            let percent = dataset_percentage[context?.dataIndex] || 0
            percent = percent.toFixed(0)
            return `${percent} %`
          },
        },
      },
      {
        data: dataset_solgt, 
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        backgroundColor: 'rgba(75,192,192,0.7)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        barPercentage: 1, // Adjust the width of each bar within its category slot
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = Object.assign({
    onHover: (event, chartElements, chart) => {
      chart.data.datasets[0].datalabels.display = false;
      chart.data.datasets[2].datalabels.display = false;

      if (chartElements.length > 0) {
        const element = chartElements[0];
        if ([0,2].indexOf(element.datasetIndex) >= 0) {
          const dataIndex = element.index;

          // Show data labels only for the hovered element in the specific dataset
          chart.data.datasets[0].datalabels = {
            display: (ctx) => ctx.dataIndex === dataIndex,
            align: 'center',
            anchor: 'center',
            color: 'white',
            offset: 15,
            font: {
              size: 16,
            },
            formatter: (value, context) => {
              if (value === 0) return null
              return numberFormatterKrNoDec(value)
            },
          };

          // Show data labels only for the hovered element in the specific dataset
          chart.data.datasets[2].datalabels = {
            display: (ctx) => ctx.dataIndex === dataIndex,
            align: 'end',
            anchor: 'end',
            color: 'white',
            offset: 15,
            font: {
              size: 16,
            },
            formatter: (value, context) => {
              if (value === 0) return null
              return numberFormatterKrNoDec(value)
            },
          };
        }
      }

      chart.update(); 
    }
  }, rotateddoublebaroptionstype)

  // Make sure the bar stretches full width (no more or less) to make the "Antall salg" fit
  options.scales.x.max = dataset_largest_max 

  return (
    <ChartContainer>
      <ChartImage src={BarsImage} alt="chartimg" />
      <HitRateLabel>Hitrate</HitRateLabel>
      <ChartLabel>Tilbud og omsetning etter type</ChartLabel>
      <StyledBar data={data} options={options} />
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 40%;
  position: relative;
`;

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`

const ChartImage = styled.img`
  position: absolute;
  width: 50px;
  top: 5px;
  left: 5px;
`

const HitRateLabel = styled.div`
  position: absolute;
  color: ${colors.teal};
  top: 30px;
  right: 6%;
  font-size: 0.8em;
`
