import React, { useState } from 'react'
import { useStore } from 'react-hookstore'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import './index.css'

export default (props) =>  {
  const [user] = useStore('user') 
  const [error, setError] = useState('')
  const [newpass, setNewpass] = useState('')
  const [,setSnackbar] = useStore('snackbar')
  const [changepass, setChangepass] = useState(false)

  const handleSubmitPassForm = async (e) => {
    e.preventDefault()
    if (newpass === '') return setError('Blankt passord ikke tillatt')
    if (newpass.length < 5) return setError('Minimum 5 karakterer')
    const update_res = await fetch(`/api/me`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: newpass })
    })
    if (!update_res.ok) {
      const text = await update_res.text()
      setError('')
      setSnackbar({ open: true, message: text, variant: 'error', showtime: 3000 })
      return
    }
    setError('')
    setNewpass('')
    setChangepass(false)
    setSnackbar({ open: true, message: 'Passord oppdatert', variant: 'success', showtime: 3000 })
  }

  const changepassText = changepass ? 'Tilbake' : 'Endre passord'

  const departments = (user.departments || []).map(d => {
    return (
      <div key={d.id}>
        {d.name}
      </div>
    )
  })

  return (
    <div className="MainBody Me">
      <div className="top">
        <h1>Min bruker</h1>
        <div className="buttons">
          <Button variant="contained" color="primary" onClick={() => setChangepass(!changepass)}>{changepassText}</Button>
        </div>
      </div>
      { !changepass &&
      <div className="userInfo">
        <div className="userInfoElement">
          <div className="key">Navn:</div>
          <div className="value">{user.name}</div>
        </div>
        <div className="userInfoElement">
          <div className="key">Epost:</div>
          <div className="value">{user.email}</div>
        </div>
        <div className="userInfoElement">
          <div className="key">Telefon:</div>
          <div className="value">{user.phone}</div>
        </div>
        <div className="userInfoElement">
          <div className="key">Kortnavn:</div>
          <div className="value">{user.shortname}</div>
        </div>
        <div className="userInfoElement">
          <div className="key">Avdeling(er):</div>
          <div className="value">
            {departments}
          </div>
        </div>
      </div>
      }
      { changepass &&
      <form className="passform" onSubmit={handleSubmitPassForm}>
          <TextField 
            value={newpass}
            onChange={(e) => setNewpass(e.target.value)}
            id="password"
            type="password"
            label="Nytt passord"
            error={error !== ''}
            variant="outlined" 
            required={true}
            className="formInput" 
            helperText={error}
            InputLabelProps={{
              shrink: true,
            }} 
          />
        <Button type="submit" variant="contained" color="primary">Endre</Button>
      </form>
      }
    </div>
  )
}
