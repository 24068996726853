import React from 'react'
import styled from 'styled-components'
import { startOfYear, addMonths, endOfMonth, differenceInMonths, isWithinInterval }  from 'date-fns'
import { Bar } from 'react-chartjs-2'
import { ChartLabel, ChartImage } from './components'
import { baroptions, colors } from './chartoptions'
import PigImage from '../../shared/graphics/pig.png'

export default ({ from, to, sales, order_reserves, }) => {

  const start = startOfYear(new Date())
  const groups = new Array(8).fill(0).map((n,index) => {
    return {
      start: addMonths(start, index*6),
      end: endOfMonth(addMonths(start, index*6+5))
    }
  })
  const labels = groups.map((g) => {
    const d1 = differenceInMonths(start, g.start)
    const d2 = differenceInMonths(start, g.end)
    const l = `${Math.abs(d1)} - ${Math.abs(d2)+1}`
    return l
  })
  const salesPer6Months = new Array(8).fill(0)

  order_reserves.forEach(sale => {
    if (!sale?.startup) return
    if (!sale?.completed) return
    // eslint-disable-next-line
    if (sale.status != '6') return

    const start = new Date(sale?.startup)
    const periodSum = parseInt(sale.sum / sale.contractperiod)
    new Array(sale.contractperiod).fill(0).forEach((_,index) => {
      const p = addMonths(start, index)
      groups.forEach((g,gi) => {
        if (isWithinInterval(p, { start: g.start, end: g.end })) {
          salesPer6Months[gi] += periodSum
        }
      })
    }) 
  })

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Akkumulert omsetning justert etter hitrate',
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg, 
        data: salesPer6Months,
      },
    ],
  };

  return (
    <Container>
      <ChartLabel>Ordrereserve Salg - Periodisert 6 MND Intervall</ChartLabel>
      <ChartImage src={PigImage} />
      <StyledBar data={chartData} options={baroptions} />
    </Container> 
  )
}

const Container = styled.div`
  width: 80%;
  position: relative;
`

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`

