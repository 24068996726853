import React from 'react'
import { Chip, FormControl, FormControlLabel } from '@material-ui/core'
import { InputLabel, InputAdornment, Button, IconButton, Slider, TextField , Checkbox , MenuItem , Select, FormHelperText} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { format } from './utils'
import FileUploadField from './formFieldsFiles'
import DepartmentRoleSelect from './departmentRoleSelect'

export const getFormField = (field, state, handleChange, inputLabel, labelWidth, props) => {
  let value = state[field.name]
  if (value === null) value = ''

  const getSelectedValue = (value) => {
    return field.options.find(option => option.id === value) 
            && field.options.find(option => option.id === value).name
  }

  if (field.type === 'departmentRoleSelect') return (
    <DepartmentRoleSelect key='departments' state={state} field={field} handleChange={handleChange} />
  )

  if (field.type === 'file') return (
    <FileUploadField key={field.name} state={state} field={field} handleChange={handleChange} handleUploadFile={props.handleUploadFile} handleDownloadFile={props.handleDownloadFile} />
  )

  if (field.type === 'text') return (
    <TextField 
      key={field.name}
      value={value} 
      disabled={field.disabled}
      onChange={handleChange(field.name)} 
      error={state[`${field.name}Error`]} 
      helperText={state[`${field.name}HelperText`]} 
      id={field.name}
      variant="outlined" 
      className="formInput" 
      label={field.label} 
      required={field.required} />
  )
  if (field.type === 'progress') {
    const valueLabelFormat = (value) => {
      return `${value}%`;
    }
    return (
      <div key="progress">
        <ProgressSlider
          key={field.name}
          value={typeof value === 'number' ? value : 0}
          onChange={handleChange(field.name)}
          aria-labelledby="input-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          min={0}
          max={100}
          disabled={field.disabled}
        />
      </div>
    )
  }
  if (field.type === 'autoComplete' || field.type === 'singleAutoComplete') {

    const renderEndAdornment = (endAdornment, inputValue) => {
      if (!field.addOnMissing) return (
        <>
        {endAdornment}
        </>
      )
      const match = field.options.find(o => o.name === inputValue)
      return (
        <>
        {!match && inputValue.length > 3 &&
          <Button onClick={() => {field.handleAddOnMissing(inputValue)}} variant="contained" color="primary" size="small">
            Legg til
          </Button>
        }
        {endAdornment}
        </>
      )
    }

   return (    
    <Autocomplete
      multiple={field.type === 'autoComplete'}
      key={field.name}
      onChange={(event, newValue) => handleChange(field.name, newValue, field.type)(event)}
      className={`autoCompleteWrapper ${field.name}`} 
      id={field.name}
      variant="outlined" 
      options={field.options}
      getOptionLabel={(option) => option?.name || ''}
      filterSelectedOptions
      value={value || ''}
      disabled={field.disabled}
      renderInput={(params) => {
        return (
          <TextField {...params} 
            label={field.label} 
            error={state[`${field.name}Error`]} 
            variant="outlined" 
            required={field.required} 
            className={`formInput ${field.name}`} 
            helperText={state[`${field.name}HelperText`]}  
            InputProps={{
              ...params.InputProps,
              endAdornment: renderEndAdornment(params.InputProps.endAdornment, params.inputProps.value),
            }}
          />
        )
      }
    }
    />
    )
   }
  if (field.type === 'textarea') return (
    <TextField 
      key={field.name}
      value={value} 
      disabled={field.disabled}
      onChange={handleChange(field.name)} 
      error={state[`${field.name}Error`]} 
      helperText={state[`${field.name}HelperText`]} 
      id={field.name}
      variant="outlined" 
      className="formInputTextArea"
      multiline={true}
      minRows={6}
      maxRows={6}
      label={field.label} 
      required={field.required} />
  )
  if (field.type === 'number') { 
    let valueLabel = null
    let valueLabelClass = ''
    if (field.valueFormatter) {
      valueLabelClass = 'valueOverlay'
      valueLabel = (
        <InputAdornment key={`${field.name}-valueOverlay`} position="start">
          <div className="valueLabelOverlay">{field.valueFormatter(value)}</div>
        </InputAdornment>
      )
    }
    return (
      <TextField 
        key={field.name}
        value={value} 
        disabled={field.disabled}
        onChange={(event) => handleChange(field.name , parseFloat(event.target.value) , "number")(event)} 
        onFocus={(e) => { if (valueLabelClass) e.target.parentNode.parentNode.classList.remove(valueLabelClass)}}
        onBlur={(e) => { if (valueLabelClass) e.target.parentNode.parentNode.classList.add(valueLabelClass)}} 
        error={state[`${field.name}Error`]} 
        helperText={state[`${field.name}HelperText`]} 
        id={field.name}
        variant="outlined" 
        className={`formInput ${valueLabelClass}`}
        type="number"
        label={field.label} 
        required={field.required} 
        InputProps={{
          startAdornment: valueLabel
        }}
      />
    )
  }
  if (field.type === 'password') return (
    <TextField 
      key={field.name}
      value={value} 
      disabled={field.disabled}
      onChange={handleChange(field.name)} 
      error={state[`${field.name}Error`]} 
      helperText={state[`${field.name}HelperText`]} 
      id={field.name}
      variant="outlined" 
      className="formInput" 
      type="password"
      label={field.label} 
      required={field.required} />
  )
  if (field.type === 'checkbox') {
    return (
      <FormControlLabel
        key={field.name}
        control={
          <Checkbox 
            checked={state[field.name]} 
            onChange={handleChange(field.name, 'checkbox', 'checkbox')} 
            id={field.name}
            variant="outlined" 
            className="formInput checkBox" 
            label={field.label} 
            required={field.required} />
        }
        label={field.label}
      />
    )
  }
  if (field.type === 'date') {
    return (
      <TextField 
        key={field.name}
        value={format(state[field.name], 'yyyy-MM-dd')} 
        disabled={field.disabled}
        onChange={handleChange(field.name)} 
        error={state[`${field.name}Error`]} 
        helperText={state[`${field.name}HelperText`]} 
        id={field.name}
        variant="outlined" 
        className="formInput" 
        type="date"
        label={field.label} 
        InputLabelProps={{
          shrink: true,
        }} 
        required={field.required} />
    )
  }
  if (field.type === 'select') { 
   let menuItems = field.options.map(o => {
      return <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
   })
   return (
      <FormControl 
        key={field.name}
        variant="outlined" 
        className="formInput"
        required={field.required} 
        error={state[`${field.name}Error`]}>
        <CustomInputLabel ref={inputLabel} id={`demo-simple-select-outlined-${field.name}`}>{field.label}</CustomInputLabel>
        <Select
          labelId={`demo-simple-select-outlined-${field.name}`}
          id={`demo-simple-select-outlined-${field.name}`}
          value={value} 
          disabled={field.disabled}
          onChange={handleChange(field.name)}
          endAdornment={
            (value && (props?.adornment || field?.clearable)) ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear selection"
                  onClick={handleChange(field.name, null, 'reset')}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          labelWidth={labelWidth}>
          {menuItems}
        </Select>
        <FormHelperText>{state[`${field.name}HelperText`]}</FormHelperText>
      </FormControl>
    )
  }
  if (field.type === 'multiSelect') { 
    let menuItems = field.options.map(o => {
       return <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
    })
    return (
      <FormControl 
        key={field.name}
        variant="outlined" 
        className="formInput"
        required={field.required} 
        error={state[`${field.name}Error`]}>
        <InputLabel ref={inputLabel} id="demo-mutiple-chip-label">{field.label}</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={value}
          disabled={field.disabled}
          onChange={handleChange(field.name)}
          labelWidth={labelWidth}
          renderValue={(selected) => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {selected.map((value) => (
                <span key={value}>
                  <Chip 
                    key={value} 
                    label={getSelectedValue(value)} 
                    style={{margin: '2px'}}
                  />
                </span>
              ))}
            </div>
          )}
        >
          {menuItems}
        </Select>
        <FormHelperText>{state[`${field.name}HelperText`]}</FormHelperText>
    </FormControl>
     )
  }
  return null
}

const ProgressSlider = styled(Slider)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    margin-top: 2px;
  }

  & .MuiSlider-track {
    height: 14px; /* Thicker track for a more pronounced fill effect */
    border-radius: 5px; /* Rounded corners for the track */
  }

  & .MuiSlider-rail {
    height: 14px; /* Same height as the track for a uniform look */
    opacity: 0.5; /* Slightly transparent background for the un-filled part */
    border-radius: 5px; /* Rounded corners for the rail */
  }

  & .MuiSlider-valueLabel {
    left: calc(-50% + 5px);
  }
`;

const CustomInputLabel = styled(InputLabel)`
  background-color: white;
  padding: 0 5px;
`
