import React from 'react'
import { nav } from 'tiny-react-router'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10
  },
  searchIcon: {
    paddingTop: 20
  },
  datePicker: {
    marginRight: 10,
    width: 150
  },
  searchField: {
    paddingTop: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  searchFieldContainer: {
    height: 75,
    marginRight: 10
  },
  dateFilterContainer: {
    height: 75,
  }
}))

export default (props) => {
  const classes = useStyles()

  return (
    <div className={classes.headerContainer}>
      <SearchIcon className={classes.searchIcon} />
      <div className={classes.searchFieldContainer}>
        <TextField
          id="standard-search"
          label="Søk etter kunde"
          type="search"
          value={props.filters.customerSearchText}
          onChange={(e) => { props.setFilters(Object.assign({}, props.filters, { customerSearchText: e.target.value }))}}
          className={classes.searchField}
          margin="normal"
        />
      </div>
      {props.orderBy &&
        <ClearIcon className={classes.searchIcon} style={{ cursor: 'pointer' }} onClick={props.clearOrderBy} /> 
      }
      <div className="spacer" />
      <Fab color="primary" aria-label="add" onClick={() => nav('/customers/add')} >
        <AddIcon />
      </Fab>
    </div>
  )
}
