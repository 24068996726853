import React from 'react'
import styled from 'styled-components'
import { getWeek, getYear, format } from 'date-fns'
import { nb } from 'date-fns/locale';
import { colors } from './chartoptions'
import { numberFormatterKr, numberFormatterKrNoDec } from '../../shared/utils'
import ChestImage from '../../shared/graphics/chest.png'

export default ({ to, from, sales, goal }) => {

  const currentYear = new Date().getFullYear()

  // eslint-disable-next-line
  const tapt = sales.filter(s => s.status == '7').filter(s => getYear(new Date(s.completed)) === currentYear)
  // eslint-disable-next-line
  const solgt = sales.filter(s => s.status == '6').filter(s => getYear(new Date(s.completed)) === currentYear)
  // eslint-disable-next-line
  const sendt = sales.filter(s => s.status == '3').filter(s => getYear(new Date(s.received)) === currentYear)
  // eslint-disable-next-line
  const uavklart = sales.filter(s => s.status == '4').filter(s => getYear(new Date(s.received)) === currentYear)
  // eslint-disable-next-line
  const foresporsler = sales.filter(s => s.status == '1').filter(s => getYear(new Date(s.received)) === currentYear)
  // eslint-disable-next-line
  const solgt_non_ramme = sales.filter(s => s.status == '6' && [undefined, false].indexOf(s?.contract) >= 0).filter(s => getYear(new Date(s.completed)) === currentYear)

  const tapt_sum = tapt.reduce((sum,s) => sum+s.sum, 0)
  const solgt_sum = solgt.reduce((sum,s) => sum+s.sum, 0)
  const average_sale = (solgt_sum / solgt.length)
  let average_sale_sendt = average_sale * sendt.length 
  let average_sale_uavklart = average_sale * uavklart.length 
  let average_sale_foresporsel = average_sale * foresporsler.length 
  const solgt_non_ramme_sum = solgt_non_ramme.reduce((sum,s) => sum+s.sum, 0)
  let hitRate = 0
  try { hitRate = ((solgt_sum / (solgt_sum+tapt_sum)) * 100).toFixed(0) } catch(e) {}
  const weeknr = getWeek(new Date())
  const goalText = (goal / 1000000).toFixed(2)
  const target = (goal / 52) * weeknr
  const dayAndMonth = format(new Date(), 'd MMMM', { locale: nb })

  if (isNaN(hitRate)) hitRate = 0
  if (isNaN(average_sale_sendt)) average_sale_sendt = 0
  if (isNaN(average_sale_uavklart)) average_sale_uavklart = 0
  if (isNaN(average_sale_foresporsel)) average_sale_foresporsel = 0

  return (
    <TopSummaryWrapper>
      <SummaryBox width={40}>
        <HitRate>
          <Title>Hitrate</Title>
          <Value>{hitRate}%</Value>
        </HitRate>
        <YTDContainer>
          <Title>Salg YTD</Title>
          <ProgressBar value={solgt_non_ramme_sum} goal={goal} target={target} />
        </YTDContainer>
        <YTDGoalContainer>
          {`${goalText} MNOK`}
        </YTDGoalContainer> 
      </SummaryBox>
      <SummaryBox width={40}>
        <TableBox>
          <TableRow>
            <RowTitle>Ubesvarte forespørsler</RowTitle>
            <RowCount>{foresporsler.length}</RowCount>
            <RowSum>{numberFormatterKr(average_sale_foresporsel.toFixed(2))}</RowSum>
          </TableRow>
          <TableRow>
            <RowTitle>{`Status: "Tilbud sendt"`}</RowTitle>
            <RowCount>{sendt.length}</RowCount>
            <RowSum>{numberFormatterKr(average_sale_sendt.toFixed(2))}</RowSum>
          </TableRow>
          <TableRow>
            <RowTitle>{`Status: "Innl. forhandling"`}</RowTitle>
            <RowCount>{uavklart.length}</RowCount>
            <RowSum>{numberFormatterKr(average_sale_uavklart.toFixed(2))}</RowSum>
          </TableRow>
        </TableBox>
        <ChestBox>
          <ChestBoxImage src={ChestImage} />
          <ChestBoxTotal>{numberFormatterKr((average_sale_foresporsel+average_sale_sendt+average_sale_uavklart).toFixed(0))}</ChestBoxTotal>
        </ChestBox>
      </SummaryBox>
      <SummaryBox width={20}>
        <DatesBox>
          <YearDisplay>{getYear(new Date())}</YearDisplay>
          <DateDisplay>{dayAndMonth}</DateDisplay>
          <WeekNumber>{weeknr}</WeekNumber>
        </DatesBox>
      </SummaryBox>
    </TopSummaryWrapper>
  );
};

// NOTE: Gap blir ikke riktig, da det er 3 bokser og løses med margin-right for SummaryBox
const TopSummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const SummaryBox = styled.div`
  color: white; 
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1A3755;
  border-radius: 10px;
  padding: 10px;
  width: calc(${props => props.width}% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-right: 10px;
  &:last-child {
    width: calc(${props => props.width}%);
    margin-right: 0px;
  }
`;

const Title = styled.div`
  font-size: 0.75em;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Value = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: ${colors.teal};
`;

const HitRate = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const YTDContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const YTDGoalContainer = styled.div`
  display: flex;
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 5%;
`

const ProgressBar = ({ value, goal, target }) => {
  let percentage = (value / goal) * 100;
  let tpercentage = 100 - (target / goal) * 100;

  if (isNaN(percentage)) percentage = 0 
  if (isNaN(tpercentage)) tpercentage = 0 

  if (percentage > 100) percentage = 100
  if (tpercentage > 100) tpercentage = 100

  return (
    <ProgressBarWrapper>
      <BarContainer>
        <Bar percentage={percentage} />
        <Target percentage={tpercentage} />
        <BarText>{numberFormatterKrNoDec(value.toFixed(2))}</BarText>
      </BarContainer>
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled.div`
  background-color: #1A3755;
  border-radius: 10px;
  color: white;
  min-width: 300px;
`;

const BarContainer = styled.div`
  position: relative;
  height: 20px;
  background-color: #2A4971;
`;

const Bar = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background-color: ${colors.teal};
  transition: width 0.3s ease-in-out;
`;

const Target = styled.div`
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: ${({ percentage }) => percentage}%;
  width: 2px;
  border-right: 2px dotted red;
  border-width: 2px;
  transition: width 0.3s ease-in-out;
`

const BarText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 0.75em;
  color: #ffffff;
`;

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.7em;
  font-weight: bold;
  gap: 5px;
  border-right: 1px solid ${colors.teal};
  padding-right: 20px;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  border-bottom: 1px solid ${colors.teal};
  &:last-child {
    border-bottom: 0;
  }
`

const RowTitle = styled.div`
  width: 150px;
`
const RowCount= styled.div`
  flex: auto;
  text-align: right;
`
const RowSum= styled.div`
  width: 110px;
  text-align: right;
`

const ChestBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ChestBoxImage = styled.img`
  width: 50px; 
`

const ChestBoxTotal = styled.div`
  font-weight: bold;
`

const DatesBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const YearDisplay = styled.div`
  color: ${colors.teal};
  font-weight: bold;
  font-size: 1.3em;
`

const DateDisplay = styled.div`
  font-size: 1em;
`

const WeekNumber = styled.div`
  font-size: 1em;
  font-weight: bold;
`

