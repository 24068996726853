import React, { useState, useEffect } from 'react'
import { nav } from 'tiny-react-router'
import { useStore } from 'react-hookstore'
import Button from '@material-ui/core/Button'
import { getFormField } from '../../shared/formFields'
import './index.css'

export default (props) => {
  const creating = props.id === 'add'
  const [customers, setCustomers] = useStore('customers')
  // eslint-disable-next-line
  const [snackbar, setSnackbar] = useStore('snackbar')

  let fields = [
    { name: 'name',          label: 'Navn',          type: 'text', required: true }
  ]
  let fieldState = fields.reduce((_state, field) => {
    _state[field.name] = field.defaultValue !== undefined ? field.defaultValue : ''
    _state[`${field.name}Error`] = false
    _state[`${field.name}HelperText`] = false
    return _state
  }, {})
  const [state, setState] = useState(fieldState)

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [inputLabel])

  useEffect(() => {
    if (!creating) {
      // eslint-disable-next-line
      const _customers = customers.filter(p => p.id == props.id)
      if (_customers.length > 0) {
        let customer = Object.assign({}, _customers[0])
        setState(state => {
          Object.keys(customer).forEach(k => { if (customer[k] === null) customer[k] = '' })
          let _state = Object.assign({}, state, customer)
          return _state
        })
      }
    }
  }, [customers, props.id, creating])

  const handleChange = (name, type) => event => {
    let value = event.target.value
    if (type === 'date') value = event.target.value+' 12:00'
    if (type === 'checkbox') value = event.target.checked
    setState({ ...state, [name]: value });
  }
  const handleSave = async () => {
    if (creating) save(true)
    else save()
  }
  const handleCancel = async () => {
    nav('/customers')
  }
  const handleDelete = async () => {
    let confirmed = window.confirm('Sikker? Sletting er endelig')
    if (!confirmed) return
    remove()
  }

  const checkErrors = () => {
    let errors = {}
    fields.forEach(field => {
      if (field.required) {
        if (([null, '',].indexOf(state[field.name])) >= 0 || (state[field.name] && state[field.name].length === 0)) {
          let _error = {}
          _error[`${field.name}Error`] = true
          _error[`${field.name}HelperText`] = `${field.label} er påkrevd`
          Object.assign(errors, _error) 
        } else {
          let _error = {}
          _error[`${field.name}Error`] = false
          _error[`${field.name}HelperText`] = '' 
          Object.assign(errors, _error) 
        }
      }
    })
    setState(Object.assign({}, state, errors))
    let hasErrors = Object.keys(errors).reduce((sum, key) => { if (sum) return sum; return errors[key] },false)
    return hasErrors
  }

  const save = async (create=false) => {
    let hasErrors = checkErrors()
    if (hasErrors) return
    let payload = fields.reduce((col, field) => {
      col[field.name] = state[field.name]
      return col
    }, {})
    let url = create ? '/api/customer' : `/api/customer/${props.id}`
    if (!create) delete payload.id
    let res = await fetch(url, 
      { 
        method: create ? 'POST' : 'PUT', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
    if (!res.ok) return await handleAPIErrors(res)
    let customer = await res.json()
    let _customers, message;
    if (create) {
        _customers = [customer].concat(customers)
      message = 'customer opprettet'
    }
    else {
      // eslint-disable-next-line
      _customers = customers.map(s => s.id == props.id ? customer : s)
      message = 'customer oppdatert'
    }
    setCustomers(_customers)
    setSnackbar({ open: true, message: message, variant: 'success', showtime: 3000 })
    setTimeout(() => { nav(`/customers/${customer.id}`) })
  }

  const remove = async () => {
    let res = await fetch(`/api/customer/${props.id}`, {
      method: 'DELETE'
    })
    if (!res.ok) return await handleAPIErrors(res)
    // eslint-disable-next-line
    let _customers = customers.filter(s => s.id != props.id)
    setCustomers(_customers)
    setSnackbar({ open: true, message: 'customer slettet', variant: 'warning', showtime: 3000 })
    setTimeout(() => { nav(`/customers`) })
  }

  const handleAPIErrors = async (res) => {
    let code = typeof res.text === 'function' ? await res.text() : null
    if (code === '23505') code = 'id allerede i bruk'
    return setSnackbar({ open: true, message: `Feil ved lagring av customer: ${code}`, variant: 'error', showtime: 3000 })
  }

  let formFields1 = fields.filter(f => ['name'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })

  return (
    <div className="AddSale">
      <div className="heading">
        <h1 className="title">{creating ? 'Opprett' : 'Rediger'} customer</h1>
      </div>

      <form noValidate autoComplete="off">
        <div className="formRow">
          <div className="formColumn">
            {formFields1}
          </div>
        </div>
      </form>

      <div className="buttonsContainer">
        <Button variant="contained" color="primary" onClick={handleSave}>Lagre</Button>
        <Button variant="contained" color="secondary" onClick={handleCancel}>Avbryt</Button>
        <div className="spacer" />
        {!creating && 
          <Button variant="contained" color="secondary" onClick={handleDelete}>Slett</Button>
        }
      </div>
    </div>
  )
}
