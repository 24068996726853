import React, { useState } from 'react'
import { useStore } from 'react-hookstore'
import { nav } from 'tiny-react-router'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import SalesHeader from './components/SalesHeader'
import './index.css'

export default () => {
  const [users,] = useStore('users')  
  const [state, setState] = useState({})
  const [filters, setFilters] = useStore('filters')

  let filteredUsers = users 
    .map(u => {
      u.departmentname = u.departments.map(d => d?.name || '').join(', ')
      return u
    })
  if (filters.userSearchText !== '') filteredUsers = filteredUsers
    .filter(s => {
      const nameMatch = s.name.toLowerCase().indexOf(filters.userSearchText.toLowerCase()) >= 0
      const emailMatch = s.email.toLowerCase().indexOf(filters.userSearchText.toLowerCase()) >= 0
      return nameMatch || emailMatch 
    })

  const handleOrderBy = (orderByColumn) => {
    let direction = 'asc'
    if (state.orderBy === orderByColumn && state.orderByDirection === 'asc') direction = 'desc'
    setState(Object.assign({}, state, { orderBy: orderByColumn, orderByDirection: direction}))
  }

  if (state.orderBy) {
    filteredUsers = filteredUsers.sort((a,b) => {
      let _a = a[state.orderBy]
      let _b = b[state.orderBy]
      if (_a === null || _a === '') _a = state.orderBy === 'sum' ? 0 : 'x'
      if (_b === null || _b === '') _b = state.orderBy === 'sum' ? 0 : 'x'
      if (_a < _b) return -1
      if (_a > _b) return 1
      return 0
    })
    if (state.orderByDirection === 'desc') filteredUsers.reverse()
  } 

  return (
    <div className="Users">
      <SalesHeader filters={filters} setFilters={setFilters} orderBy={state.orderBy} clearOrderBy={() => { setState({ orderBy: null, orderByDirection: 'asc' }) }} /> 
      <div className="KalkulasjonTable tableContainer">
        <Paper className="paper">
          <Table className="table" size="small" aria-label="a dense table">
            <TableHead className="tableHead">
              <TableRow className="tableHeadRow">
                <TableCell className="tableCell">
                  <TableSortLabel active={state.orderBy === 'name'} direction={state.orderByDirection} onClick={() => {handleOrderBy('name')}}>Navn</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={state.orderBy === 'email'} direction={state.orderByDirection} onClick={() => {handleOrderBy('email')}}>Epost</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={state.orderBy === 'phone'} direction={state.orderByDirection} onClick={() => {handleOrderBy('phone')}}>Telefon</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={state.orderBy === 'department'} direction={state.orderByDirection} onClick={() => {handleOrderBy('department')}}>Avdeling</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={state.orderBy === 'administrator'} direction={state.orderByDirection} onClick={() => {handleOrderBy('administrator')}}>Administrator</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {filteredUsers.map(user => (
                <TableRow key={user.id} className={`Row tableRow`} onClick={() => nav(`/users/${user.id}`)} hover>
                  <TableCell className="tableCell">{user.name}</TableCell>
                  <TableCell className="tableCell">{user.email}</TableCell>
                  <TableCell className="tableCell">{user.phone}</TableCell>
                  <TableCell className="tableCell">{user.departmentname}</TableCell>
                  <TableCell className="tableCell">{`${user.administrator ? 'Ja' : 'Nei'}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  )
}
