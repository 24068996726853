import React from 'react';
import styled from 'styled-components';
import { startOfYear, addMonths, endOfMonth, differenceInMonths, isWithinInterval }  from 'date-fns'
import { Bar } from 'react-chartjs-2';
import { ChartLabel } from './components'
import { baroptions, colors } from './chartoptions'

export default ({ to, from, sales, order_reserves }) => {

  const start = startOfYear(new Date())
  const groups = new Array(4).fill(0).map((n,index) => {
    return {
      start: addMonths(start, index*12),
      end: endOfMonth(addMonths(start, index*12+11))
    }
  })
  const labels = groups.map((g) => {
    const d2 = differenceInMonths(start, g.end)
    const l = `${Math.abs(d2)+1} MND`
    return l
  })
  const salesPer12Months = new Array(4).fill(0)

  order_reserves.forEach(sale => {
    if (!sale?.startup) return
    if (!sale?.completed) return
    // eslint-disable-next-line
    if (sale.status != '6') return

    const start = new Date(sale?.startup)
    const periodSum = parseInt(sale.sum / sale.contractperiod)
    new Array(sale.contractperiod).fill(0).forEach((_,index) => {
      const p = addMonths(start, index)
      groups.forEach((g,gi) => {
        if (isWithinInterval(p, { start: g.start, end: g.end })) {
          salesPer12Months[gi] += periodSum
        }
      })
    }) 
  })

  const data = {
    labels, 
    datasets: [
      {
        data: salesPer12Months, 
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg, 
        barPercentage: 0.4
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartLabel>Ordrereserve Sum</ChartLabel>
      <StyledBar data={data} options={baroptions} />
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 20%;
  position: relative;
`;

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`
