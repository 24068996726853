import React from 'react'
import styled from 'styled-components'
import { getWeek, getYear } from 'date-fns'
import { Line } from 'react-chartjs-2'
import { ChartLabel, ChartImage } from './components'
import { colors, lineoptions } from './chartoptions'
import RequestsImage from '../../shared/graphics/requests.png'

export default ({ from, to, sales }) => {

  const labels = new Array(52).fill(0).map((n,index) => index+1)
  const salesPerWeek = new Array(52).fill(0);
  const currentYear = new Date().getFullYear()

  sales.forEach(sale => {
    const year = getYear(new Date(sale.received))
    if (year === currentYear) {
      const weekNumber = getWeek(new Date(sale.received)) - 1; // -1 to make it 0-indexed
      salesPerWeek[weekNumber]++;
    }
  })

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Antall forespørsler',
        fill: false,
        lineTension: 0.1,
        borderColor: colors.teal, 
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        tension: 0.4,
        data: salesPerWeek, 
      },
    ],
  };

  return (
    <Container>
      <ChartLabel>Antall forespørsler</ChartLabel>
      <ChartImage src={RequestsImage} />
      <StyledLine data={chartData} height={50} options={lineoptions} />
    </Container> 
  )
}

const Container = styled.div`
  width: 80%;
  position: relative;
`

const StyledLine = styled(Line)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`

