import React, { useState, useEffect } from 'react'
import { nav, getPath } from 'tiny-react-router'
import { useStore } from 'react-hookstore'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import Logo from '../../graphics/serwent-logo.webp'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    cursor: 'pointer',
    display: 'flex',
  },
  navButton: {
    marginRight: theme.spacing(1),
  },
  navButtonSelected: {
    marginRight: theme.spacing(1),
    color: `${theme.palette.primary.main}`,
    backgroundColor: 'white' 
  }
}))

const StyledBadge1 = withStyles(theme => ({
  badge: {
    right: 8,
    top: 2
  },
}))(Badge)

export default function ButtonAppBar(props) {
  const classes = useStyles()
  // eslint-disable-next-line
  const [user, setUser] = useStore('user')
  const [, setPath] = useState('')

  useEffect(() => {
    const handleChange = () => setPath(getPath()) 
    window.addEventListener('hashchange', handleChange)
    return () => {
      window.removeEventListener('hashchange', handleChange)
    }
  })

  const handleLogoNav = () => {
    if (!user) nav('/login')
    else nav('/')
  }

  const handleLogout = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    nav('/login')
    await fetch('/api/logout')
    setUser(null) 
  }

  const role_ids = (user?.departments || []).map(d => d.role_id)
  const isLeader = role_ids.indexOf(1) >= 0
  const isSalesman = role_ids.indexOf(2) >= 0
  //const isObserver = role_ids.indexOf(3) >= 0
  const isAdministrator = user?.administrator

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          { user && 
          <>
          <StyledBadge1 color="secondary" badgeContent={0} overlap="rectangular">
            <Button 
              color="inherit" 
              className={(getPath() === '/' || getPath().startsWith('/dashboard')) ? classes.navButtonSelected : classes.navButton} 
              href="#/dashboard">
              Dashbord 
            </Button>
          </StyledBadge1>
          { (isAdministrator || isLeader || isSalesman) &&
          <>
          <Badge color="secondary" badgeContent={0} overlap="rectangular">
            <Button 
              color="inherit" 
              className={getPath().startsWith('/sales') ? classes.navButtonSelected : classes.navButton} 
              href="#/sales">
              Salg 
            </Button>
          </Badge>
          <StyledBadge1 color="secondary" badgeContent={0} overlap="rectangular">
            <Button 
              color="inherit" 
              className={getPath().startsWith('/contracts') ? classes.navButtonSelected : classes.navButton} 
              href="#/contracts">
              Rammeavtaler 
            </Button>
          </StyledBadge1>
          </>
          }
          { user.administrator &&
            <>
            <StyledBadge1 color="secondary" badgeContent={0} overlap="rectangular">
              <Button 
                color="inherit" 
                className={getPath().startsWith('/users') ? classes.navButtonSelected : classes.navButton} 
                href="#/users">
                Brukere
              </Button>
            </StyledBadge1>
            <StyledBadge1 color="secondary" badgeContent={0} overlap="rectangular">
              <Button 
                color="inherit" 
                className={getPath().startsWith('/customers') ? classes.navButtonSelected : classes.navButton} 
                href="#/customers">
                Kunder
              </Button>
            </StyledBadge1>
            <StyledBadge1 color="secondary" badgeContent={0} overlap="rectangular">
              <Button 
                color="inherit" 
                className={getPath().startsWith('/missions') ? classes.navButtonSelected : classes.navButton} 
                href="#/missions">
                Oppdrag
              </Button>
            </StyledBadge1>
            </>
          }
          <StyledBadge1 color="secondary" badgeContent={0} overlap="rectangular">
            <Button 
              color="inherit" 
              className={getPath().startsWith('/account') ? classes.navButtonSelected : classes.navButton} 
              href="#/account">
              Konto
            </Button>
          </StyledBadge1>
          <StyledBadge1 color="secondary" badgeContent={0} overlap="rectangular">
            <Button 
              color="inherit" 
              className={classes.navButton} 
              onClick={handleLogout}
              href="#/login">
              Logg ut
            </Button>
          </StyledBadge1>
          </>
          }
          <div className="spacer" />
          <Typography variant="h6" className={classes.title} onClick={handleLogoNav}>
            <img alt="logo" src={Logo} height="50" /> 
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}
