import React from 'react';
import styled from 'styled-components';

// Styled component for the circular track
const CircularTrack = styled.svg`
  transform: rotate(-90deg);
`;

// Styled component for the progress bar
const ProgressBar = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform-origin: center;
`;

// React component for the circular progress bar
const CircularProgressbar = ({ sale, status }) => {
  const color = status?.color || 'white'
  const percentage = status?.percentage || 0 
  const radius = 18; // radius of the circle
  const circumference = 2 * Math.PI * radius; // circumference of the circle
  const strokeDashoffset = ((100 - percentage) / 100) * circumference;

  return (
    <CircularTrack width="40px" height="40px" viewBox="0 0 40 40">
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="transparent"
        stroke="rgba(238, 238, 238, 0.2)"
        strokeWidth="4"
      />
      <ProgressBar
        cx="20"
        cy="20"
        r={radius}
        fill="transparent"
        stroke={color}
        strokeWidth="4"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
    </CircularTrack>
  );
};

export default CircularProgressbar;

