import React from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { differenceInDays } from 'date-fns'
import { ChartLabel } from './components'
import { baroptionsstacked, colors } from './chartoptions'

export default ({ to, from, sales }) => {

  const uavklart = sales
    // eslint-disable-next-line
    .filter(s => s.status == '4')

  const now = new Date()
  let siste30 = 0
  let siste60 = 0
  let over60  = 0

  uavklart.forEach(s => {
    const diff = differenceInDays(now, new Date(s.received))
    if (diff <= 30) siste30 += s.sum
    else if (diff > 30 && diff <= 60) siste60 += s.sum
    else if (diff > 60) over60 += s.sum
  })

  const data = {
    labels: [''], 
    datasets: [
      {
        data: [siste30], 
        backgroundColor: colors.teal,
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.7)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        barPercentage: 0.4
      },
      {
        data: [siste60], 
        backgroundColor: '#43AAA7',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.7)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        barPercentage: 0.4
      },
      {
        data: [over60], 
        backgroundColor: '#5FC1BF',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.7)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        barPercentage: 0.4
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartLabel>Uavklarte tilbud</ChartLabel>
      <StyledBar data={data} options={baroptionsstacked} />
      <SummaryTable>
        <SummaryTableRow>
          <SummaryTableLabel><ColorBox color={colors.lighter} /><Label>60 dager +</Label></SummaryTableLabel><SummaryTableValue>{over60}</SummaryTableValue>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableLabel><ColorBox color={colors.light} /><Label>30 - 60 dager</Label></SummaryTableLabel><SummaryTableValue>{siste60}</SummaryTableValue>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableLabel><ColorBox color={colors.teal} /><Label>0 - 30 dager</Label></SummaryTableLabel><SummaryTableValue>{siste30}</SummaryTableValue>
        </SummaryTableRow>
      </SummaryTable>
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 20%;
  position: relative;
  display: flex;
  background-color: #1A3755;
  border-radius: 10px;
`;

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
  margin-bottom: 60px;
`
const SummaryTable = styled.div`
  position: absolute;
  bottom: 0px;
  left: 10%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: white;
  width: 70%;
`
const SummaryTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid white;
`
const SummaryTableLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const SummaryTableValue = styled.div``
const Label = styled.div``
const ColorBox = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.color || 'white'};
  margin-right: 5px;
`
