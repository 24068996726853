import styled from 'styled-components'
import { colors } from '../chartoptions'

export const ChartLabelInline = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  white-space: nowrap;
`

export const ChartLabel = styled(ChartLabelInline)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
`

export const ChartImage = styled.img`
  width: 50px;
  position: absolute;
  top: 6%;
  right: 2%;
`

export const ChartSum = styled.div`
  color: ${colors.teal};
  font-size: 1.5em;
`
