import React from 'react';
import styled from 'styled-components';
import { getYear } from 'date-fns'
import { Bar } from 'react-chartjs-2';
import { useStore } from 'react-hookstore'
import { ChartLabel } from './components'
import { top3salesoptions, colors } from './chartoptions'
import { numberFormatterKrNoDec } from './../../shared/utils'
import UserImage from './../../shared/graphics/user.png'
import TopUserImage from './../../shared/graphics/topuser.png'

const userImage = new Image();
userImage.src = UserImage;
const topUserImage = new Image();
topUserImage.src = TopUserImage;

export default ({ to, from, sales }) => {
  const [users] = useStore('users')

  const currentYear = new Date().getFullYear()

  let top3solgt = sales
    // eslint-disable-next-line
    .filter(s => s.status == '6')
    .filter(s => s.completed)
    .reduce((res, s) => {
      const year = getYear(new Date(s.completed))
      if (year !== currentYear) return res 
      if (!res[s.responsible]) res[s.responsible] = {
        sum: 0,
        sales: [],
        user: users.find(u => u.id === s.responsible)
      }
      res[s.responsible].sales.push(s)
      res[s.responsible].sum += s.sum
      return res
    }, {})
  top3solgt = Object.values(top3solgt).sort((a,b) => {
    if (a.sum > b.sum) return -1
    if (a.sum < b.sum) return 1
    return 0
  })

  const num_people_to_show = 100 // NOTE: sette bare et høyt tall her så alle blir med...
  let labels = top3solgt.filter((t,i) => i < num_people_to_show).map(t => t.user.name)
  let dataset = top3solgt.filter((t,i) => i < num_people_to_show).map(t => t.sum) 
  let numsales = top3solgt.filter((t,i) => i < num_people_to_show).map(t => t.sales.length) 
  let topsum = (top3solgt[0]?.sum || 0) + (0.05 * (top3solgt[0]?.sum || 0))
  let numsalesmax = top3solgt.filter((t,i) => i < num_people_to_show).map(t => topsum) 

  const plugins = [{
    id: 'customImageDatalabels',
    afterDraw: function(chart, args, options) {
      var ctx = chart.ctx;
      var yAxis = chart.scales['y'];

      yAxis.ticks.forEach(function(value, index) {
        const img = index === 0 ? topUserImage : userImage
        const width = index === 0 ? 50 : 40 
        const left = index === 0 ? 45 : 40 
        const top = index === 0 ? 25 : 20 
        // Modify this to position your image as required
        var x = yAxis.left - left;
        var y = yAxis.getPixelForTick(index) - top;
        ctx.drawImage(img, x, y, width, width);
      });
    }
  }]

  const data = {
    labels, 
    datasets: [
      {
        data: dataset, 
        borderWidth: 0,
        backgroundColor: colors.teal, 
        barPercentage: 0.4,
        categoryPercentage: 1,
        hoverBackgroundColor: colors.hoverbg, 
        datalabels: {
          display: false,
        },
      },
      {
        data: numsalesmax, 
        type: 'line',
        backgroundColor: 'rgba(0,0,0,0)', // Make the bar background transparent
        borderColor: 'rgba(0,0,0,0)', // Make the bar border transparent
        hoverBackgroundColor: 'rgba(0,0,0,0)', // Make the hover background transparent
        hoverBorderColor: 'rgba(0,0,0,0)', // Make the hover border transparent
        barPercentage: 0,
        categoryPercentage: 0,
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'center',
          textAlign: 'center',
          color: 'white',
          font: {
            size: 14,
          },
          formatter: (value, context) => {
            if (value === 0) return null
            return numsales[context.dataIndex] 
          },
        },
      },
    ],
  };

  const datasetIndexToHover = 0;
  const options = Object.assign({
    onHover: (event, chartElements, chart) => {
      chart.data.datasets[datasetIndexToHover].datalabels.display = false;

      if (chartElements.length > 0) {
        const element = chartElements[0];
        if (element.datasetIndex === datasetIndexToHover) {
          const dataIndex = element.index;

          // Show data labels only for the hovered element in the specific dataset
          chart.data.datasets[datasetIndexToHover].datalabels = {
            display: (ctx) => ctx.dataIndex === dataIndex,
            align: 'top',
            anchor: 'center',
            color: 'white',
            offset: 15,
            font: {
              size: 16,
            },
            formatter: (value, context) => {
              if (value === 0) return null
              return numberFormatterKrNoDec(value)
            },
          };
        }
      }

      chart.update(); 
    }
  }, top3salesoptions)

  // Make sure the bar stretches full width (no more or less) to make the "Antall salg" fit
  options.scales.x.max = topsum

  return (
    <ChartContainer>
      <HitRateLabel>Antall salg</HitRateLabel>
      <ChartLabel>Omsetning - Selgere</ChartLabel>
      <StyledBar data={data} options={options} plugins={plugins} />
    </ChartContainer>
  );
};

// Styled container for the chart
const ChartContainer = styled.div`
  width: 80%;
  position: relative;
`;

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`

const HitRateLabel = styled.div`
  position: absolute;
  color: ${colors.teal};
  top: 30px;
  right: 2%;
  font-size: 0.8em;
`
