export const DG_MIN_POLICY = 25

export const COLORS = {
  'KVIA_BLUE': '#0C71C3'
}

export const PROJECT_TYPES = [
  {
    id: 'total',
    label: 'TOTAL (totalenterprise)',
  },
  {
    id: 'he',
    label: 'HE (hovedenterprise)',
  },
  {
    id: 'ue',
    label: 'UE (underenterprise)',
  },
  {
    id: 'stoyskjerm',
    label: 'Støyskjerm',
  },
]
