import React, { useState, useEffect } from 'react'
import { useStore } from 'react-hookstore'
import Button from '@material-ui/core/Button'
import { format } from '../../shared/utils'
import { getFormField } from '../../shared/formFields'
import './Mission.css'

export default ({ mission, editing, creating, onEdit, onSave, onCancel, onDelete, onCopy, planning, repeatClone, selectedDate }) =>  {
  const edit = editing || creating
  const [type] = useStore('type')
  const [users] = useStore('users')
  const [operators] = useStore('operators')
  const [customers, setCustomers] = useStore('customers')
  const [,setSnackbar] = useStore('snackbar')

  const sortedOperators = operators.sort((a,b) => {
    if (a?.name === b?.name) return 0
    return (a?.name > b?.name) ? 1 : -1
  })

  const addNewCustomer = async (name) => {
    const res = await fetch(`/api/customer`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name })
    })
    if (!res.ok) return setSnackbar({ open: true, message: 'Kunne ikke opprette kunde', variant: 'error', showtime: 3000 })
    setSnackbar({ open: true, message: 'Kunne opprettet', variant: 'success', showtime: 3000 })
    const customer = await res.json()
    const _customers = [customer].concat(customers)
    setCustomers(_customers)
    setFields(currentFields => {
      let updatedFields = currentFields.map(f => {
        if (f.name === 'customer') f.options = _customers
        return f
      })
      return updatedFields
    })
    setState(currentState => {
      return { ...currentState, customer }
    })
  }

  let field = [
    { name: 'operator', label: 'Operatør', type: 'select', options: sortedOperators, required: false, disabled: false, clearable: true },
    { name: 'customer', label: 'Kunde', type: 'singleAutoComplete', options: customers, required: true, disabled: false, addOnMissing: true, handleAddOnMissing: addNewCustomer },
    { name: 'type', label: 'Oppdragstype', type: 'select', options: type, required: true, disabled: false },
    { name: 'address', label: 'Adresse', type: 'text', required: false, disabled: false },
    { name: 'contact', label: 'Kontaktperson', type: 'text', required: false, disabled: false },
    { name: 'phonenumber', label: 'Telefonnummer', type: 'text', required: false, disabled: false },
    { name: 'markings', label: 'Merking', type: 'text', required: false, disabled: false },
    { name: 'sidekick', label: 'Sidemann', type: 'select', options: sortedOperators, required: false, disabled: false, clearable: true },
    { name: 'description', label: 'Beskrivelse', type: 'textarea', required: true, disabled: false },
    { name: 'ordered', label: 'Bestilt', type: 'date', disabled: false },
    { name: 'deadline', label: 'Deadline', type: 'date', disabled: false },
    { name: 'planned', label: 'Planlagt', type: 'date', disabled: false },
    { name: 'repeat', label: 'Repeter', type: 'number', disabled: false },
  ]
  const [fields, setFields] = useState(field)
  let fieldState = fields.reduce((_state, field) => {
    _state[field.name] = field.defaultValue !== undefined ? field.defaultValue : ''
    _state[`${field.name}Error`] = false
    _state[`${field.name}HelperText`] = false
    return _state
  }, {})
  const [state, setState] = useState(fieldState)
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [inputLabel])

  useEffect(() => {
    if (!mission && selectedDate) {
      setState((state) => { return { ...state, planned: selectedDate } })
    } 
    if (mission && editing) {
      const _mission = Object.assign({}, mission)
      if (mission.customer) _mission.customer = customers.filter(i => i.id === mission.customer)[0]
      setState((state) => { return { ...state, ..._mission } })
    }
  }, [mission, editing, customers, type, selectedDate])

  const handleChange = (name, value, type) => (event, newval) => {
    let val = event?.target?.value
    if (type === 'file') val = value
    if (type === 'reset') val = value
    if (type === 'number') val = value
    if (type === 'checkbox') val = event.target.checked
    if (name === 'progress') val = newval
    if (name === 'customer') val = value
    if (name === 'customers') val = value
    if (name === 'attachments') val = event
    setState({ ...state, [name]: val });
  }

  const handleSave = async () => {
    let { operator, customer, type, address, contact, phonenumber, markings, sidekick, repeat, description, ordered, deadline, planned, executed } = state
    if (!customer?.id) return setSnackbar({ open: true, message: `Mangler kunde`, variant: 'error', showtime: 3000 })
    if (description === '') return setSnackbar({ open: true, message: `Mangler beskrivelse`, variant: 'error', showtime: 3000 })
    if (operator === '') operator = null
    if (sidekick === '') sidekick = null
    if (repeat === '') repeat = null
    if (onSave) onSave({ mission, editing, creating, editState: { 
      operator, 
      customer: customer?.id, 
      type, address, contact, phonenumber, markings, sidekick, description, repeat, 
      ordered: ordered ? format(ordered,'yyyy-MM-dd') : null, 
      deadline: deadline ? format(deadline,'yyyy-MM-dd') : null, 
      planned: planned ? format(planned,'yyyy-MM-dd') : null,
      executed: executed ? format(executed,'yyyy-MM-dd') : null
    }})
  }

  const handleDoubleClick = (mission) => {
    if (!repeatClone) return onEdit(mission)
    // NOTE: If repeatClone, we should modify the planned date and call onCopy
    const _mission = Object.assign({}, mission)
    _mission.planned = format(selectedDate,'yyyy-MM-dd')
    _mission.copied_from = mission.id
    delete _mission.id
    delete _mission.ordered
    delete _mission.deadline
    delete _mission.operator
    delete _mission.sidekick
    delete _mission.sort_order
    delete _mission.repeat
    onCopy(_mission)
  }

  const getDataField = (field, addlabel) => {
    let value = mission[field.name]
    // eslint-disable-next-line
    if (field.name === 'type') value = type.find(o => o.id == value)?.name
    if (field.name === 'operator') value = operators.find(o => o.id === value)?.name
    if (field.name === 'customer') value = customers.find(o => o.id === value)?.name
    if (field.name === 'sidekick') value = operators.find(o => o.id === value)?.name
    if (field.name === 'ordered') value = format(value)
    if (field.name === 'deadline') value = format(value)
    if (field.name === 'planned') value = format(value)
    if (addlabel) value = `${field.label}: ${value}`
    if (field.name === 'operator' && repeatClone) value = '' 
    return value
  }

  const leftFields = fields.filter(f => ['operator'].indexOf(f.name) >= 0).map(f => {
    if (edit) return <div key={f.name} className="fieldContainer">{getFormField(f, state, handleChange, inputLabel, labelWidth)}</div>
    return <div key={f.name} className={`fieldContainer view`}>{getDataField(f)}</div>
  })
  const topFormFields = fields.filter(f => ['customer', 'type', 'address', 'contact', 'phonenumber', 'markings', 'sidekick', 'repeat'].indexOf(f.name) >= 0).map(f => {
    if (edit) return <div key={f.name} className="fieldContainer">{getFormField(f, state, handleChange, inputLabel, labelWidth)}</div>
    if (f.name === 'repeat') return null
    let bold = ''
    if (f.name === 'customer') bold = 'bold'
    return (
      <div key={f.name} className={`fieldContainer view ${f.name}`}>
        <div className="label">{f.label}</div>
        <div className={`value ${bold}`}>{getDataField(f)}</div>
      </div>
    )
  })
  const bottomFormFields = fields.filter(f => ['description'].indexOf(f.name) >= 0).map(f => {
    if (edit) return <div key={f.name} className="fieldContainer">{getFormField(f, state, handleChange, inputLabel, labelWidth)}</div>
    if (planning) return null
    return <div key={f.name} className="fieldContainer view description">{getDataField(f)}</div>
  })
  const rightFormFields = fields.filter(f => ['ordered', 'deadline', 'planned'].indexOf(f.name) >= 0).map(f => {
    if (edit) return <div key={f.name} className="fieldContainer">{getFormField(f, state, handleChange, inputLabel, labelWidth)}</div>
    if (planning && ['ordered', 'deadline'].indexOf(f?.name) >= 0) return null
    return <div key={f.name} className="fieldContainer view">{getDataField(f, true)}</div>
  })

  let creator = mission?.creator
  if (creator) creator = users.find(u => u.id === creator)?.name

  let sort_order = (mission?.sort_order || 0) + 1
  if (!mission?.operator) sort_order = 0
  if (repeatClone) sort_order = 'R'

  let mainClasses = 'Mission'
  if (!mission?.operator) mainClasses += ' noOperator'
  if (repeatClone) mainClasses += ' repeatClone'

  return (
    <div className={mainClasses} onDoubleClick={() => handleDoubleClick(mission)}>
      <div className={`number ${planning ? 'planning' : ''}`}>
        <div className="missionNumber">
            {sort_order}
        </div>
        <div className="operator">
          {leftFields}
        </div>
      </div>
      <div className="details">
        <div className="topfields">
          {topFormFields}
        </div>
        <div className="bottomfields">
          {bottomFormFields}
        </div>
        <div className="buttons">
          { (editing || creating) &&
          <>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
          <Button variant="contained" onClick={onCancel}>Cancel</Button>
          { mission?.id &&
            <>
            <div style={{ flex: 'auto' }} />
            <Button variant="contained" color="primary" onClick={() => onCopy(mission)}>Kopier</Button>
            <Button variant="contained" color="secondary" onClick={() => onDelete(mission)}>Slett</Button>
            </>
          }
          </>
          }
          { (!editing || !creating) && mission?.repeat && !repeatClone &&
          <div className="repeatInfo">{`Repeter: ${mission?.repeat} (dag)`}</div>
          }
        </div>
      </div>
      <div className="dates">
        {rightFormFields}
      </div>
      { (editing || creating) && creator &&
      <div className="creator">{`${creator}`}</div>
      }
    </div>
  )
}
