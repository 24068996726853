import { format as _format, addHours } from 'date-fns'
import { differenceInDays, isEqual, isAfter } from 'date-fns'

export function missionRepeatsOnDate(mission, dateToCheck) {
//  console.log(parseISO(mission.planned), parseISO(dateToCheck))
//  console.log(format(mission.planned), format(dateToCheck))
  const plannedDate = new Date(mission.planned); // Ensure this is a Date object
  const repeatInterval = mission.repeat; // Should be an integer (number of days)
  const _dateToCheck = new Date(dateToCheck);
  if (isAfter(plannedDate, _dateToCheck)) return false
  if (isEqual(plannedDate, _dateToCheck)) return false
  const daysDifference = differenceInDays(_dateToCheck, plannedDate);
//  console.log(mission.repeat, daysDifference, daysDifference % repeatInterval)
  return daysDifference % repeatInterval === 0;
}


export function format(date, struct='dd.MM.yyyy', def='') {
  if (!date) return def
  if (date === '') return def
  try {
    return _format(addHours(new Date(date),1), struct)
  }
  catch(e) {
    return def
  }
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const numFormatter = new Intl.NumberFormat('nb-NO', {
  style: 'currency',
  currency: 'NOK',
});
const numFormatterDec = new Intl.NumberFormat('nb-NO', {
  style: 'decimal',
  currency: 'NOK',
});
const numFormatterKrNoDec = new Intl.NumberFormat('nb-NO', {
  style: 'currency',
  currency: 'NOK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const numberFormatterKr = numFormatter.format 
export const numberFormatterKrNoDec = numFormatterKrNoDec.format 
export const numberFormatterNoPrefix = numFormatterDec.format
