import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, ListItemIcon, Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

export default function FileUploadField({ state, field, handleChange, handleUploadFile, handleDownloadFile }) {
  const [existingFiles, setExistingFiles] = useState([]);

  useEffect(() => {
    if (!Array.isArray(state[field.name])) return
    setExistingFiles(state[field.name])
  }, [existingFiles, field, state])

  const onDrop = useCallback(async acceptedFiles => {
    for (const file of acceptedFiles) {
      await handleUploadFile(file)
    }
  }, [handleUploadFile]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: field.disabled
  })

  const handleRemoveExistingFile = (e, fileToRemove) => {
    e.preventDefault()
    e.stopPropagation()
    const c = window.confirm('Sikker på at du vil slette vedlegget?')
    if (!c) return
    const new_files = existingFiles.filter(f => f.s3filename !== fileToRemove.s3filename)
    handleChange('attachments')(new_files)
  }

  const handleDownloadFileHere = (e, fileToDownload) => {
    e.preventDefault()
    e.stopPropagation()
    handleDownloadFile(fileToDownload)
  }

  return (
    <section>
      <Paper variant="outlined" {...getRootProps()} style={{ padding: '20px', textAlign: 'center', cursor: 'pointer', marginTop: '0', marginBottom: '10px' }}>
        <input {...getInputProps()} />
        <Typography variant="body1">Legg til vedlegg</Typography>
        <FileListContainer>
          {existingFiles.map((file, index) => (
            <FileListItem key={index} file={file} onRemove={handleRemoveExistingFile} onClick={handleDownloadFileHere} field={field} />
          ))}
        </FileListContainer>
      </Paper>
    </section>
  );
}

function FileListItem({ file, onRemove, onClick, field }) {

  const getFileIcon = (file) => {
    if (file.type.startsWith('image/')) return <ImageIcon style={{ fontSize: '4em' }} />;
    if (file.type === 'application/pdf') return <PictureAsPdfIcon  style={{ fontSize: '4em' }} />;
    return <InsertDriveFileIcon style={{ fontSize: '4em' }} />; // Default icon
  };

  return (
    <FileItemContainer onClick={(e) => onClick(e, file)}>
      <ListItemIcon>{getFileIcon(file)}</ListItemIcon>
      <StyledFileName title={file.name}>
        {file.name.length > 15 ? `${file.name.substring(0, 12)}...` : file.name}
      </StyledFileName>
      <Typography variant="caption">{file.size ? `${(file.size / 1024).toFixed(2)} KB` : null}</Typography>
      { !field.disabled &&
      <RemoveIconButton size="small" onClick={(e) => onRemove(e, file)}>
        <CloseIcon fontSize="small" />
      </RemoveIconButton>
      }
    </FileItemContainer>
  );
}

const FileItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:hover .remove-icon {
    visibility: visible;
  }
  width: 120px;
`;

const FileListContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 10px;
`

// Styled Typography to prevent wrapping
const StyledFileName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
`;

// Styled IconButton for the remove action
const RemoveIconButton = styled(IconButton).attrs({
  className: "remove-icon",
})`
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
`;

