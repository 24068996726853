import React from 'react'
import styled from 'styled-components'
import { getYear, getWeek } from 'date-fns'
import { Bar } from 'react-chartjs-2'
import { ChartLabel, ChartImage } from './components'
import { baroptions, colors } from './chartoptions'
import CoinsImage from '../../shared/graphics/coins.png'

export default ({ from, to, sales, average_sum, hitrate }) => {

  const labels = new Array(52).fill(0).map((n,index) => index+1)
  const salesPerWeek = new Array(52).fill(0);
  const currentYear = new Date().getFullYear()

  sales.forEach(sale => {
    const year = getYear(new Date(sale.received))
    if (year === currentYear) {
      const weekNumber = getWeek(new Date(sale.received)) - 1; // -1 to make it 0-indexed
      salesPerWeek[weekNumber] += average_sum*(hitrate/100);
    }
  })

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Akkumulert omsetning justert etter hitrate',
        borderWidth: 0,
        backgroundColor: colors.teal, 
        hoverBackgroundColor: colors.hoverbg, 
        data: salesPerWeek,
      },
    ],
  };

  return (
    <Container>
      <ChartLabel>Akkumulert omsetning basert på forespørsler, justert etter hitrate</ChartLabel>
      <ChartImage src={CoinsImage} />
      <StyledBar data={chartData} options={baroptions} />
    </Container> 
  )
}

const Container = styled.div`
  width: 80%;
  position: relative;
`

const StyledBar = styled(Bar)`
  border-radius: 10px;
  background-color: #1A3755;
  padding: 10px;
`

