import React, { useState, useRef } from 'react'
import { useStore } from 'react-hookstore'
import { nav, getPath } from 'tiny-react-router'
import { differenceInDays } from 'date-fns'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Filters from '../../shared/components/Filters'
import ProgressComponent from '../../shared/components/Progress'
import { format, numberFormatterNoPrefix } from '../../shared/utils'
import './index.css'

export default () => {
  const [users,] = useStore('users')
  const [statuses,] = useStore('status')
  const [customers,] = useStore('customers')
  const editNotesRef = useRef(null)
  const [sales, setSales] = useStore('sales')
  const [filters, setFilters] = useStore('filters')
  const [, setSnackbar] = useStore('snackbar')
  const [editNotesSale, setEditNotesSale] = useState(null)
  const contracts = getPath().startsWith('/contracts')

  const joinCustomers = (sale) => {
    // eslint-disable-next-line
    const cust = customers.find(_c => _c.id == sale.customer)?.name || ''
    // eslint-disable-next-line
    const custs = sale.customers.map(c => customers.find(_c => _c.id == c)?.name || '')
    const firstmark = custs.length > 0 ? ',' : ''
    return `${cust}${firstmark} ${custs.join(', ')}`
  }

  let filteredSales = sales 
  if (contracts) filteredSales = filteredSales.filter(s => s?.contract)
  if (filters.saleSearchText !== '') filteredSales = filteredSales.filter(s => {
    const nameMatch = s.name.toLowerCase().indexOf(filters.saleSearchText.toLowerCase()) >= 0
    const customerMatch = joinCustomers(s).toLowerCase().indexOf(filters.saleSearchText.toLowerCase()) >= 0
    const responsible = users.find(u => u.id === s.responsible)
    const responsiblename = (responsible?.name || '')
    const responsiblenameMatch = responsiblename.toLowerCase().indexOf(filters.saleSearchText.toLowerCase()) >= 0
    return nameMatch || customerMatch || responsiblenameMatch 
  })
  if (filters.customer.length > 0) filteredSales = filteredSales.filter(s => {
    const customerMatch = filters.customer.map(r => r.id).indexOf(s.customer) >= 0
    const customersMatch = filters.customer.map(r => r.id).filter(id => s.customers.includes(id)).length > 0
    return customerMatch || customersMatch
  })
  if (filters.responsible.length > 0) filteredSales = filteredSales.filter(s => filters.responsible.map(r => r.id).indexOf(s.responsible) >= 0)
  if (filters.status.length > 0) filteredSales = filteredSales.filter(s => filters.status.map(s => s.id.toString()).indexOf(s.status) >= 0)
  if (filters.department.length > 0) filteredSales = filteredSales.filter(s => filters.department.map(d => d.id).indexOf(s.department) >= 0)

//  let foresporsel = filteredSales.filter(s => s.status === '1')
//  let kalkulasjon = filteredSales.filter(s => s.status === '2')
//  let aktiv       = filteredSales.filter(s => s.status === '3')
//  let uavklart    = filteredSales.filter(s => s.status === '4')
//  let tapt        = filteredSales.filter(s => s.status === '5')
//  let solgt       = filteredSales.filter(s => s.status === '6')
//  let rest        = filteredSales.filter(s => ['1','2','3','4','5','6'].indexOf(s.status) < 0)

  filteredSales = filteredSales.map(s => { 
    // eslint-disable-next-line
    s._status = statuses.find(_s => _s.id == s.status)
    // eslint-disable-next-line
    s.statusname = s?._status?.name || '' 
    s.responsiblename = users.find(u => u.id === s.responsible)?.shortname || ''
    return s
  })

  const handleOrderBy = (orderByColumn) => {
    let direction = 'asc'
    if (filters.orderBy === orderByColumn && filters.orderByDirection === 'asc') direction = 'desc'
    setFilters({ ...filters, orderBy: orderByColumn, orderByDirection: direction })
//    setState(Object.assign({}, state, { orderBy: orderByColumn, orderByDirection: direction}))
  }

  const handleSaleChange = async (sale, payload) => {
    const res = await fetch(`/api/sales/${sale.id}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(payload)
    })
    if (!res.ok) return await handleAPIErrors(res)
    let _sale = await res.json()
    let _sales, message;
    // eslint-disable-next-line
    _sales = sales.map(s => s.id == _sale.id ? _sale : s)
    message = 'Salg oppdatert'
    setSales(_sales)
    setSnackbar({ open: true, message: message, variant: 'success', showtime: 3000 })
    setEditNotesSale(null)
  }

  const handleAPIErrors = async (res) => {
    let code = typeof res.text === 'function' ? await res.text() : null
    if (code === '23505') code = 'id allerede i bruk'
    return setSnackbar({ open: true, message: `Feil ved lagring av salg: ${code}`, variant: 'error', showtime: 3000 })
  }

  const handleChangeNotes = (e, sale) => {
    e.preventDefault()
    e.stopPropagation()
    setEditNotesSale(sale.id)
  }

  const salename = (sale) => {
    let name = (sale.name || '').substring(0,30)
    // NOTE: Bare hvis ikke levert
    if (sale.deadline && !sale.delivered) {
      const days_to_deadline = differenceInDays(new Date(), new Date(sale.deadline))
      let icons = ''
      if (days_to_deadline > -10) icons = '⚠️ '
      if (days_to_deadline > 0) icons = '🛑 '
      name = icons+name
    }
    return name
  }

  if (filters.orderBy) {
    filteredSales = filteredSales.sort((a,b) => {
      let _a = a[filters.orderBy]
      let _b = b[filters.orderBy]
      if (filters.orderBy === 'progress') {
        _a = a?._status?.percentage || 0
        _b = b?._status?.percentage || 0
      }
      if (_a === null || _a === '') _a = filters.orderBy === 'sum' ? 0 : 'x'
      if (_b === null || _b === '') _b = filters.orderBy === 'sum' ? 0 : 'x'
      if (_a < _b) return -1
      if (_a > _b) return 1
      return 0
    })
    if (filters.orderByDirection === 'desc') filteredSales.reverse()
  } 

  return (
    <div className="Sales">
      <Filters 
        orderBy={filters.orderBy} 
        clearOrderBy={() => { setFilters({ ...filters, orderBy: null, orderByDirection: 'asc' }) }} 
      /> 
      <SalesTableContainer>
        <SalesCount>{`Antall treff: ${filteredSales.length}`}</SalesCount>
        <Paper className="paper">
          <Table className="table" size="small" aria-label="a dense table">
            <TableHead className="tableHead">
              <TableRow className="tableHeadRow">
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'name'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('name')}}>Navn</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'responsible'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('responsible')}}>Ansvarlig</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'responsible'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('progress')}}>Fremdrift</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'customer'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('customer')}}>Kunde</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'deadline'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('deadline')}}>Frist</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'delivered'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('delivered')}}>Levert</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'sum'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('sum')}}>Sum</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={filters.orderBy === 'notes'} direction={filters.orderByDirection} onClick={() => {handleOrderBy('notes')}}>Notat</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {filteredSales.map(sale => (
                <TableRow key={sale.id} className={`${sale.statusname.replace(' ','')}Row tableRow`} onClick={() => nav(`/sales/${sale.id}`)} hover>
                  <TableCell className="tableCell">{salename(sale)}</TableCell>
                  <TableCell className="tableCell">{sale.responsiblename}</TableCell>
                  <TableCell className="tableCell"><ProgressComponent sale={sale} status={statuses.find(_s => _s.id.toString() === sale.status)} /></TableCell>
                  <TableCell className="tableCell">{joinCustomers(sale)}</TableCell>
                  <TableCell className="tableCell">{format(sale.deadline)}</TableCell>
                  <TableCell className="tableCell">{format(sale.delivered)}</TableCell>
                  <TableCell className="tableCell right">{numberFormatterNoPrefix(sale.sum)}</TableCell>
                  <TableCell className="tableCell" onClick={(e) => handleChangeNotes(e, sale)}>
                    { editNotesSale === sale.id &&
                      <div className="notesEditorContainer">
                        <textarea defaultValue={sale.notes} ref={editNotesRef} />
                        <div className="buttons">
                          <Button onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            handleSaleChange(sale, { notes: editNotesRef.current.value })
                          }} variant="contained" color="primary">Lagre</Button>
                          <Button onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setEditNotesSale(null)
                          }} variant="contained" color="secondary">Avbryt</Button>
                        </div>
                      </div>
                    }
                    { !(editNotesSale === sale.id) &&
                      (sale.notes || '').substring(0,50)
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </SalesTableContainer>
    </div>
  )
}

const SalesTableContainer = styled.div`
  position: relative;
`

const SalesCount = styled.div`
  position: absolute;
  top: 8px;
  right: 5px;
  font-weight: bold;
  color: var(--color-text-grey);
`
