import React, { useState, useEffect } from 'react'
import { nav } from 'tiny-react-router'
import { useStore } from 'react-hookstore'
import Button from '@material-ui/core/Button'
import { getFormField } from '../../shared/formFields'
import './index.css'

export default (props) => {
  const creating = props.id === 'add'
  const [users, setUsers] = useStore('users')
  const [departments] = useStore('departments')
  const [roles] = useStore('roles')
  // eslint-disable-next-line
  const [snackbar, setSnackbar] = useStore('snackbar')

  let fields = [
    { name: 'name',          label: 'Navn',          type: 'text', required: true },
    { name: 'email',         label: 'Epost',         type: 'text', required: true },
    { name: 'phone',         label: 'Telefon',       type: 'text' },
    { name: 'password',      label: 'Passord',       type: 'password' },
    { name: 'shortname',     label: 'Kortnavn',      type: 'text', required: true },
    { name: 'departments',   label: 'Avdeling',      type: 'departmentRoleSelect', options: departments, roles, required: false, defaultValue: [] },
    { name: 'administrator', label: 'Administrator', type: 'checkbox', defaultValue: false }
  ]
  let fieldState = fields.reduce((_state, field) => {
    _state[field.name] = field.defaultValue !== undefined ? field.defaultValue : ''
    _state[`${field.name}Error`] = false
    _state[`${field.name}HelperText`] = false
    return _state
  }, {})
  const [state, setState] = useState(fieldState)

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [inputLabel])

  useEffect(() => {
    if (!creating) {
      // eslint-disable-next-line
      const _users = users.filter(p => p.id == props.id)
      if (_users.length > 0) {
        let user = Object.assign({}, _users[0])
        setState(state => {
          Object.keys(user).forEach(k => { if (user[k] === null) user[k] = '' })
          let _state = Object.assign({}, state, user)
          _state.departments = _state.departments.map(d => ({department: d.id, role: d.role_id}))
          return _state
        })
      }
    }
  }, [users, props.id, creating])

  const handleChange = (name, type) => event => {
    let value = event?.target?.value
    if (type === 'date') value = event.target.value+' 12:00'
    if (type === 'checkbox') value = event.target.checked
    if (type === 'departments') value = event
    setState({ ...state, [name]: value });
  }
  const handleSave = async () => {
    if (creating) save(true)
    else save()
  }
  const handleCancel = async () => {
    nav('/users')
  }
  const handleDelete = async () => {
    let confirmed = window.confirm('Sikker? Sletting er endelig')
    if (!confirmed) return
    remove()
  }

  const checkErrors = () => {
    let errors = {}
    fields.forEach(field => {
      if (field.required) {
        if (([null, '',].indexOf(state[field.name])) >= 0 || (state[field.name] && state[field.name].length === 0)) {
          let _error = {}
          _error[`${field.name}Error`] = true
          _error[`${field.name}HelperText`] = `${field.label} er påkrevd`
          Object.assign(errors, _error) 
        } else {
          let _error = {}
          _error[`${field.name}Error`] = false
          _error[`${field.name}HelperText`] = '' 
          Object.assign(errors, _error) 
        }
      }
    })
    setState(Object.assign({}, state, errors))
    let hasErrors = Object.keys(errors).reduce((sum, key) => { if (sum) return sum; return errors[key] },false)
    return hasErrors
  }

  const save = async (create=false) => {
    let hasErrors = checkErrors()
    if (hasErrors) return
    let payload = fields.reduce((col, field) => {
      col[field.name] = state[field.name]
      return col
    }, {})
    let url = create ? '/api/users' : `/api/users/${props.id}`
    if (!create) delete payload.id
    let res = await fetch(url, 
      { 
        method: create ? 'POST' : 'PUT', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
    if (!res.ok) return await handleAPIErrors(res)
    let user = await res.json()
    let _users, message;
    if (create) {
      _users = [user].concat(users)
      message = 'Bruker opprettet'
    }
    else {
      // eslint-disable-next-line
      _users = users.map(s => s.id == props.id ? user : s)
      message = 'Bruker oppdatert'
    }
    setUsers(_users)
    setSnackbar({ open: true, message: message, variant: 'success', showtime: 3000 })
    setTimeout(() => { nav(`/users/${user.id}`) })
  }

  const remove = async () => {
    let res = await fetch(`/api/users/${props.id}`, {
      method: 'DELETE'
    })
    if (!res.ok) return await handleAPIErrors(res)
    // eslint-disable-next-line
    let _users = users.filter(s => s.id != props.id)
    setUsers(_users)
    setSnackbar({ open: true, message: 'Bruker slettet', variant: 'warning', showtime: 3000 })
    setTimeout(() => { nav(`/users`) })
  }

  const handleAPIErrors = async (res) => {
    let code = typeof res.text === 'function' ? await res.text() : null
    if (code === '23505') code = 'id allerede i bruk'
    return setSnackbar({ open: true, message: `Feil ved lagring av bruker: ${code}`, variant: 'error', showtime: 3000 })
  }

  let formFields1 = fields.filter(f => ['name','email','shortname','phone','password','administrator'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields2 = fields.filter(f => ['departments'].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields3 = fields.filter(f => [].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields4 = fields.filter(f => [].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })
  let formFields5 = fields.filter(f => [].indexOf(f.name) >= 0).map((field, index) => {
    return getFormField(field, state, handleChange, inputLabel, labelWidth)
  })

  return (
    <div className="EditUser">
      <div className="heading">
        <h1 className="title">{creating ? 'Opprett' : 'Rediger'} bruker</h1>
      </div>

      <form noValidate autoComplete="off">
        <div className="formRow">
          <div className="formColumn">
            {formFields1}
          </div>
          <div className="formColumn">
            {formFields2}
          </div>
          <div className="formColumn">
            {formFields3}
          </div>
        </div>
        <div className="formRow">
          <div className="formColumnFlex">
            {formFields4}
          </div>
          <div className="formColumn">
            {formFields5}
          </div>
        </div>
      </form>

      <div className="buttonsContainer">
        <Button variant="contained" color="primary" onClick={handleSave}>Lagre</Button>
        <Button variant="contained" color="secondary" onClick={handleCancel}>Avbryt</Button>
        <div className="spacer" />
        {!creating && 
          <Button variant="contained" color="secondary" onClick={handleDelete}>Slett</Button>
        }
      </div>
    </div>
  )
}
