import React, { useState } from 'react'
import { useStore } from 'react-hookstore'
import { nav } from 'tiny-react-router'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import SalesHeader from './components/SalesHeader'
import './index.css'

export default () => {
  const [customers] = useStore('customers')
  const [state, setState] = useState({})
  const [filters, setFilters] = useStore('filters')

  let filteredCustomers = customers
  if (filters.customerSearchText !== '') filteredCustomers = filteredCustomers.filter(s => {
    const nameMatch = s.name?.toLowerCase().indexOf(filters.customerSearchText.toLowerCase()) >= 0
    return nameMatch
  })

  const handleOrderBy = (orderByColumn) => {
    let direction = 'asc'
    if (state.orderBy === orderByColumn && state.orderByDirection === 'asc') direction = 'desc'
    setState(Object.assign({}, state, { orderBy: orderByColumn, orderByDirection: direction}))
  }

  if (state.orderBy) {
    filteredCustomers = filteredCustomers.sort((a,b) => {
      let _a = a[state.orderBy]
      let _b = b[state.orderBy]
      if (_a === null || _a === '') _a = state.orderBy === 'sum' ? 0 : 'x'
      if (_b === null || _b === '') _b = state.orderBy === 'sum' ? 0 : 'x'
      if (_a < _b) return -1
      if (_a > _b) return 1
      return 0
    })
    if (state.orderByDirection === 'desc') filteredCustomers.reverse()
  } 

  return (
    <div className="Users">
      <SalesHeader filters={filters} setFilters={setFilters} orderBy={state.orderBy} clearOrderBy={() => { setState({ orderBy: null, orderByDirection: 'asc' }) }} /> 
      <div className="KalkulasjonTable tableContainer">
        <Paper className="paper">
          <Table className="table" size="small" aria-label="a dense table">
            <TableHead className="tableHead">
              <TableRow className="tableHeadRow">
                <TableCell className="tableCell">
                  <TableSortLabel active={state.orderBy === 'name'} direction={state.orderByDirection} onClick={() => {handleOrderBy('name')}}>Navn</TableSortLabel>
                </TableCell>
                <TableCell className="tableCell">
                  <TableSortLabel active={state.orderBy === 'visma'} direction={state.orderByDirection} onClick={() => {handleOrderBy('visma')}}>Visma</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {filteredCustomers.map(cust => (
                <TableRow key={cust.id} className={`Row tableRow`} onClick={() => nav(`/customers/${cust.id}`)} hover>
                  <TableCell className="tableCell">{cust.name}</TableCell>
                  <TableCell className="tableCell">{cust.visma}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  )
}
